import React from 'react'
import NavigationBar from '../../components/NavigationBar'
import CommonHeroSection from '../../components/CommonHeroSection'
import Management from '../../assets/managementBig.jpg'
import Revenue from '../../assets/revenue.jpg'
import Contact from '../../components/homeSectionComponents/contact/Contact'
import Footer from '../../components/Footer'

const RevenuCycleManagement = () => {
    return (
        <div>
            <NavigationBar />
            <CommonHeroSection image={Management} title="REVENUE CYCLE MANAGEMENT" />
            <div className='m-auto py-[50px] max-w-[1400px] px-[20px] sm:px-[40px]'>
                <h1 style={{ color: '#424656' }} className='text-[24px] md:text-[32px] tracking-[0.01em] font-semibold'>What is Revenue Cycle Management?</h1>
                <p className='text-[14px] md:text-[16px] mt-[5px]'>Revenue Cycle Management (RCM) is a critical process in the healthcare industry that ensures the efficient management of administrative and clinical functions related to claims processing, payment, and revenue generation. It encompasses various tasks, including patient scheduling, charge capture, claims submission, payment posting, and patient billing. The ultimate objective of RCM is to ensure proper reimbursement for healthcare services while adhering to regulatory compliance standards.</p>


                <div className='flex flex-col gap-[40px] md:flex-row mt-[70px]'>
                    <div className='w-[100%] md:w-[60%]'>
                        <h1 style={{ color: '#424656' }} className='text-[24px] md:text-[32px] tracking-[0.01em] font-semibold'>Advantages of Revenue Cycle Management</h1>
                        <p className='text-[14px] md:text-[16px] mt-[10px]'><span className='font-medium text-[15px] md:text-[17px]'>Enhanced Operational Efficiency :</span> Implementing RCM streamlines administrative processes, minimizing errors and expediting claims processing and reimbursement. This efficiency translates into cost savings and improved productivity.</p>
                        <p className='text-[14px] md:text-[16px] mt-[10px]'><span className='font-medium text-[15px] md:text-[17px]'>Improved Financial Performance :</span> By identifying and addressing inefficiencies in the revenue cycle, providers can optimize revenue streams and reduce costs, leading to improved financial performance for their organization.</p>
                        <p className='text-[14px] md:text-[16px] mt-[10px]'><span className='font-medium text-[15px] md:text-[17px]'>Regulatory Compliance and Risk Mitigation :</span> RCM helps healthcare providers stay up-to-date with ever-changing regulatory requirements. By maintaining compliance, organizations minimize the risk of penalties and legal issues.</p>
                        <p className='text-[14px] md:text-[16px] mt-[10px]'><span className='font-medium text-[15px] md:text-[17px]'>Enhanced Patient Experience :</span> Efficient administrative processes free up valuable time for healthcare providers to focus on patient care, resulting in a better overall patient experience. Patients receive prompt attention, and healthcare professionals can devote more attention to their specific needs.</p>
                        <p className='text-[14px] md:text-[16px] mt-[10px]'><span className='font-medium text-[15px] md:text-[17px]'>Effective Data Management :</span> RCM ensures accurate and comprehensive records of patients' financial data. This enables healthcare providers to identify trends, patterns, and potential areas for improvement. Informed decision-making can be achieved by leveraging this data.</p>
                        <p className='text-[14px] md:text-[16px] mt-[10px]'><span className='font-medium text-[15px] md:text-[17px]'>Informed Decision-Making :</span> Informed Decision-Making: With accurate and up-to-date information about their revenue cycle, providers can make informed decisions regarding staffing, budgeting, resource allocation, and other vital aspects of their business operations.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'>By implementing robust Revenue Cycle Management, healthcare organizations can optimize their financial performance, enhance patient care, and navigate the complex landscape of the healthcare industry with confidence.</p>
                    </div>
                    <div className='image-container w-[100%] md:w-[40%]'>
                        <div className="image-content" style={{ backgroundImage: `url(${Revenue})` }} />
                    </div>
                </div>
            </div>
            <Contact />
            <Footer />
        </div>
    )
}

export default RevenuCycleManagement