import React from 'react'
import NavigationBar from '../../components/NavigationBar'
import CommonHeroSection from '../../components/CommonHeroSection'
import Records from '../../assets/records.jpg'
import MedicalRecordReview2 from '../../assets/medicalRecordReview2.jpg'
import MedicalRecordReview3 from '../../assets/medicalRecordReview3.jpg'
import Contact from '../../components/homeSectionComponents/contact/Contact'
import Footer from '../../components/Footer'

const MedicalRecordReview = () => {
    return (
        <div>
            <NavigationBar />
            <CommonHeroSection image={Records} title="MEDICAL RECORDS REVIEW" />
            <div className='m-auto py-[50px] max-w-[1400px] px-[20px] sm:px-[40px]'>
                <div className='flex flex-col-reverse gap-[40px] md:flex-row'>
                    <div className='image-container w-[100%] md:w-[45%]'>
                        <div className="image-content" />
                    </div>
                    <div className='w-[100%] md:w-[55%]'>
                        <h1 style={{ color: '#424656' }} className='text-[18px] md:text-[24px] tracking-[0.01em] font-semibold'>The Significance of Medical Records and the Role of Medical Record Review Services</h1>
                        <p className='text-[14px] md:text-[16px] mt-[5px]'>In the realm of healthcare, medical records encompass a comprehensive collection of documents associated with an individual's medical journey. From initial doctor's visits to lab test reports, hospital records, and yearly physicals, these records provide a detailed account of a person's medical history. The volume of medical records may vary based on individual circumstances. To ensure accuracy and thoroughness in representing an individual's medical information, medical chronology services play a critical role.</p>
                        <h4 style={{ color: '#424656' }} className='mt-[50px] text-[18px] md:text-[24px] tracking-[0.01em] font-semibold'>The Importance and Benefits of Medical Record Review Services</h4>
                        <p className='text-[14px] md:text-[16px] mt-[5px]'>Care-MD offers invaluable services that contribute to various aspects of healthcare. For hospitals and healthcare systems, detailed and well-organized patient records are essential for ensuring appropriate treatment. Through comprehensive medical record audits, hospitals can easily access patient information, identify missing gaps in documentation, and prioritize patient safety and quality of care.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'>In the context of medico-legal cases, medical data plays a crucial role in substantiating evidence and achieving favorable outcomes. Legal medical record reviews enable concise and accurate summarization of medical records, making it easier for lawyers to review pertinent information. Moreover, a comprehensive medical record review helps identify any inconsistencies in treatment suggestions, medical billing, or coding, facilitating efficient management of medical claims.</p>
                    </div>
                </div>

                <div className='flex flex-col gap-[40px] md:flex-row mt-[100px]'>
                    <div className='w-[100%] md:w-[55%]'>
                        <h1 style={{ color: '#424656' }} className='text-[24px] md:text-[32px] tracking-[0.01em] font-semibold'>Key Services Offered by Medical Record Review Companies</h1>
                        <h4 style={{ color: '#424656' }} className='mt-[10px] text-[16px] md:text-[20px] tracking-[0.01em] font-semibold'>Medical record review companies provide a range of services designed to enhance the accessibility and clarity of medical records. These services typically include:</h4>
                        <p className='text-[14px] md:text-[16px] mt-[10px]'><span className='font-medium text-[15px] md:text-[17px]'>Chronological organization :</span> Skillfully arranging medical records in chronological order, offering a clear timeline and facilitating a comprehensive understanding of the individual's medical journey.</p>
                        <p className='text-[14px] md:text-[16px] mt-[10px]'><span className='font-medium text-[15px] md:text-[17px]'>Transcription and interpretation :</span> Transcribing physician notes or interviews, along with accurate interpretation of medical records, ensuring clarity and precision in the documentation.</p>
                        <p className='text-[14px] md:text-[16px] mt-[10px]'><span className='font-medium text-[15px] md:text-[17px]'>Summarization and audit checklist creation :</span> Creating concise summaries of case history and developing audit checklists for easy review by lawyers and healthcare professionals, enabling efficient assessment of relevant information.</p>
                        <p className='text-[14px] md:text-[16px] mt-[10px]'><span className='font-medium text-[15px] md:text-[17px]'>Identification of gaps and inconsistencies :</span> Identification of gaps and inconsistencies: Thoroughly examining clinical history to identify missing records and treatment inconsistencies, promoting accuracy and quality assurance.</p>
                    </div>
                    <div className='image-container w-[100%] md:w-[45%]'>
                        <div className="image-content" style={{ backgroundImage: `url(${MedicalRecordReview2})`, backgroundPosition: 'top' }} />
                    </div>
                </div>

                <div className='flex flex-col-reverse gap-[40px] md:flex-row mt-[100px]'>
                    <div className='image-container w-[100%] md:w-[45%]'>
                        <div className="image-content" style={{ backgroundImage: `url(${MedicalRecordReview3})`, backgroundPosition: 'top' }} />
                    </div>

                    <div className='w-[100%] md:w-[55%]'>
                        <h1 style={{ color: '#424656' }} className='text-[24px] md:text-[32px] tracking-[0.01em] font-semibold'>Professional Medical Record Review Services for Optimal Outcomes</h1>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'>Medical chronology services play a pivotal role in various medical claims, such as workers' compensation, insurance claims, and personal injury cases. Attorneys often require well-organized and comprehensive medical record analyses to navigate the complexities of these claims. Proper organization of medical records is crucial to avoid missed information or confusion due to medical terminology. A professionally organized and chronologically arranged medical chart review offers clarity and simplifies the review process. By partnering with a reputable medical record review outsourcing company, like Care-MD, attorneys can ensure the availability of chronologically arranged and professionally summarized medical records, ultimately supporting their legal endeavors.</p>
                    </div>
                </div>
            </div>
            <Contact />
            <Footer />
        </div>
    )
}

export default MedicalRecordReview