import React from 'react'
import NavigationBar from '../../components/NavigationBar'
import CommonHeroSection from '../../components/CommonHeroSection'
import CallCenter from '../../assets/callCenter1.jpg'
import CallCenter2 from '../../assets/callCenter2.jpg'
import CallCenter3 from '../../assets/callCenter3.jpg'
import CallCenter4 from '../../assets/callCenter4.jpg'
import CallCenter5 from '../../assets/callCenter5.jpg'
import Contact from '../../components/homeSectionComponents/contact/Contact'
import Footer from '../../components/Footer'

const AppointmentSchedulingServicesAndCallCenter = () => {
    return (
        <div>
            <NavigationBar />
            <CommonHeroSection image={CallCenter} title="APPOINTMENT SCHEDULING & CALL CENTER" />
            <div className='m-auto py-[50px] max-w-[1400px] px-[20px] sm:px-[40px]'>
                <h1 style={{ color: '#424656' }} className='text-[20px] md:text-[24px] tracking-[0.01em] font-semibold'>Care-MD - Your Reliable 24/7 Appointment Scheduling Solution</h1>
                <p className='text-[14px] md:text-[16px] mt-[5px]'>Efficiently managing appointments is crucial for the success of your business. However, without a robust system in place, it can become a costly distraction or result in missed opportunities. That's where Care-MD steps in to provide you with superior appointment scheduling services that help you capture more leads and win more business.</p>

                <div className='flex flex-col gap-[40px] md:flex-row mt-[30px] md:mt-[50px]'>
                    <div className='w-[100%] md:w-[60%]'>
                        <h1 style={{ color: '#424656' }} className='text-[20px] md:text-[24px] tracking-[0.01em] font-semibold'>Elevate Your Appointment Scheduling with Care-MD</h1>
                        <p className='text-[14px] md:text-[16px] mt-[5px]'><span className='font-medium text-[15px] md:text-[17px]'>Live Virtual Receptionists :</span> Our highly trained virtual receptionists are available round the clock to handle your appointment-related phone calls professionally. With their expertise and friendly demeanor, they ensure a positive customer experience while freeing up your time to focus on running your business.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'><span className='font-medium text-[15px] md:text-[17px]'>24/7 Availability :</span> Care-MD offers seamless appointment scheduling services 24 hours a day, 7 days a week. Whether your customers or clients need to schedule, reschedule, or manage appointments, we are here to assist them whenever they need us.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'><span className='font-medium text-[15px] md:text-[17px]'>Lead Screening & Intake :</span> Our skilled agents conduct thorough lead screening and intake processes, ensuring that only qualified leads are scheduled, saving you time and maximizing the efficiency of your appointments.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'><span className='font-medium text-[15px] md:text-[17px]'>Appointment Reminders :</span> Care-MD goes the extra mile by providing appointment reminders through various channels, including SMS and email alerts. This helps reduce no-shows and keeps your schedule running smoothly.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'><span className='font-medium text-[15px] md:text-[17px]'>IVR & Call Routing :</span> We utilize Interactive Voice Response (IVR) and intelligent call routing systems to efficiently handle incoming calls and direct them to the appropriate scheduling agents. This ensures prompt and accurate appointment scheduling for your customers.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'><span className='font-medium text-[15px] md:text-[17px]'>Professional Agents :</span> Rest assured that your customers are interacting with professional agents who understand the importance of delivering exceptional service and maintaining confidentiality.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'><span className='font-medium text-[15px] md:text-[17px]'>Call Analytics :</span> Care-MD provides valuable call analytics and insights, enabling you to track and measure the performance of your appointment scheduling services. These insights empower you to make informed decisions and continuously improve your operations.</p>
                    </div>
                    <div className='image-container w-[100%] md:w-[40%]'>
                        <div className="image-content" style={{ backgroundImage: `url(${CallCenter2})` }} />
                    </div>
                </div>

                <div className='flex flex-col-reverse gap-[40px] md:flex-row mt-[50px] md:mt-[100px]'>
                    <div className='image-container w-[100%] md:w-[40%]'>
                        <div className="image-content" style={{ backgroundImage: `url(${CallCenter3})` }} />
                    </div>
                    <div className='w-[100%] md:w-[60%]'>
                        <h1 style={{ color: '#424656' }} className='text-[20px] md:text-[24px] tracking-[0.01em] font-semibold'>The Benefits of Care-MD's Appointment Scheduling Services</h1>
                        <p className='text-[14px] md:text-[16px] mt-[5px]'><span className='font-medium text-[15px] md:text-[17px]'>Increased Efficiency :</span> By entrusting the task of handling inbound calls and booking appointments to our answering service, your office staff can focus on other essential tasks, increasing overall efficiency.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'><span className='font-medium text-[15px] md:text-[17px]'>Enhanced Customer Satisfaction :</span> Our courteous and professional virtual receptionists ensure that every customer interaction is positive and leaves a lasting impression. This fosters customer satisfaction and builds loyalty towards your business.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'><span className='font-medium text-[15px] md:text-[17px]'>Cost Savings :</span> Partnering with Care-MD for appointment scheduling services eliminates the need to hire additional staff, resulting in cost savings for your business. This is particularly advantageous for small businesses with limited budgets.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'><span className='font-medium text-[15px] md:text-[17px]'>Flexibility :</span> Our services offer the flexibility to book appointments outside of regular office hours, accommodating the scheduling needs of customers in different time zones and catering to high call volumes. This level of flexibility sets your business apart and enhances customer convenience.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'><span className='font-medium text-[15px] md:text-[17px]'>Scalability :</span> Care-MD's appointment scheduling services can be easily scaled up or down to meet the changing demands of your business. Whether you experience seasonal fluctuations or growth, our services adapt to ensure seamless appointment management.</p>
                    </div>
                </div>

                <h1 style={{ color: '#424656' }} className='text-[20px] md:text-[24px] tracking-[0.01em] font-semibold mt-[100px]'>Reducing No-Shows with Automated Appointment Reminders</h1>
                <p className='text-[14px] md:text-[16px] mt-[5px]'>Missed appointments can have a significant financial impact on businesses, costing billions of dollars annually. On average, around 20% of appointments result in no-shows, translating to potential losses of $260 per missed appointment. At Care-MD, we understand the importance of minimizing no-shows, and that's why we offer advanced appointment reminder solutions to address this challenge.</p>

                <div className='flex flex-col gap-[40px] md:flex-row mt-[50px] md:mt-[100px]'>
                    <div className='w-[100%] md:w-[60%]'>
                        <h1 style={{ color: '#424656' }} className='text-[20px] md:text-[24px] tracking-[0.01em] font-semibold'>Powerful Appointment Reminder Solutions by Care-MD</h1>
                        <p className='text-[14px] md:text-[16px] mt-[5px]'><span className='font-medium text-[15px] md:text-[17px]'>Automated Reminders :</span> Our CMS Appointment Reminder solutions utilize automated systems to send timely reminders to your customers before their scheduled appointments. By leveraging both IVR/voice-based and SMS (text message) reminders, we ensure that your customers receive their reminders through their preferred communication channel.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'><span className='font-medium text-[15px] md:text-[17px]'>Reducing No-Shows :</span> Care-MD's appointment reminder system is designed to significantly reduce no-shows by proactively engaging with your customers and keeping your appointments on their radar. By providing convenient and timely reminders, we increase the likelihood of customers attending their scheduled appointments.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'><span className='font-medium text-[15px] md:text-[17px]'>Maximizing Revenue :</span> Our appointment reminder services aim to optimize your business's revenue. By offloading the responsibility of appointment scheduling to our expert team, your staff can concentrate on more critical tasks, improving their productivity and efficiency.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'><span className='font-medium text-[15px] md:text-[17px]'>Efficiency and Time Management :</span> With Care-MD managing appointment reminders, your staff can better manage their time and focus on essential responsibilities. By streamlining their workflow, we empower them to deliver exceptional service while ensuring that your customers are well-informed about their upcoming appointments.</p>
                    </div>
                    <div className='image-container w-[100%] md:w-[40%]'>
                        <div className="image-content" style={{ backgroundImage: `url(${CallCenter4})` }} />
                    </div>
                </div>

                <div className='flex flex-col gap-[40px] md:flex-row mt-[50px] md:mt-[100px]'>
                    <div className='w-[100%] md:w-[60%]'>
                        <h1 style={{ color: '#424656' }} className='text-[20px] md:text-[24px] tracking-[0.01em] font-semibold'>Partner with Care-MD for Improved Revenue Generation</h1>
                        <p>Our goal is to help your business thrive by reducing no-shows and maximizing your revenue potential. With Care-MD as your appointment reminder partner, you can expect</p>
                        <p className='text-[14px] md:text-[16px] mt-[25px]'><span className='font-medium text-[15px] md:text-[17px]'>Increased Appointment Attendance :</span> By leveraging our automated appointment reminders, you can significantly reduce the likelihood of no-shows and ensure a higher rate of appointment attendance.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'><span className='font-medium text-[15px] md:text-[17px]'>Cost Savings :</span> Minimizing no-shows not only saves your business potential revenue losses but also avoids wasted resources and staff time associated with missed appointments. This results in cost savings for your organization.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'><span className='font-medium text-[15px] md:text-[17px]'>Enhanced Customer Experience :</span> Care-MD's appointment reminder solutions contribute to a positive customer experience by providing convenient and timely reminders. This professionalism and attention to detail build trust and loyalty among your customers.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'><span className='font-medium text-[15px] md:text-[17px]'>Streamlined Operations :</span> By entrusting your appointment reminders to Care-MD, you can streamline your operations and improve efficiency. This allows your staff to focus on core tasks that drive your business's growth.</p>
                    </div>
                    <div className='image-container w-[100%] md:w-[40%]'>
                        <div className="image-content" style={{ backgroundImage: `url(${CallCenter5})` }} />
                    </div>
                </div>

                <h1 style={{ color: '#424656' }} className='text-[20px] md:text-[24px] tracking-[0.01em] font-semibold mt-[30px] md:mt-[50px]'>Choose Care-MD for Effective Appointment Reminder Solutions</h1>
                <p className='text-[14px] md:text-[16px] mt-[5px]'>Care-MD is dedicated to providing tailored and effective business service solutions, including appointment reminder services. Let us help you reduce no-shows, optimize revenue generation, and enhance customer satisfaction. Contact us today to explore how our appointment reminder solutions can benefit your business. With Care-MD as your partner, you can rely on our expertise and cutting-edge technology to improve your appointment management processes.</p>


                <h1 style={{ color: '#424656' }} className='text-[20px] md:text-[24px] tracking-[0.01em] font-semibold mt-[30px] md:mt-[50px]'>Experience the Care-MD Difference</h1>
                <p className='text-[14px] md:text-[16px] mt-[5px]'>Discover the advantages of leveraging Care-MD's appointment scheduling services to streamline your operations, enhance customer satisfaction, and maximize your business's potential. Contact us today to discuss your unique requirements and experience the benefits of a reliable and customized appointment scheduling solution. With Care-MD as your partner, you can focus on delivering exceptional service while we take care of your appointment scheduling needs.</p>
            </div>
            <Contact />
            <Footer />
        </div>
    )
}

export default AppointmentSchedulingServicesAndCallCenter