import React from 'react'
import NavigationBar from '../../components/NavigationBar'
import CommonHeroSection from '../../components/CommonHeroSection'
import Legal from '../../assets/legalBig.jpg'
import Legal1 from '../../assets/legal1.jpg'
import Contact from '../../components/homeSectionComponents/contact/Contact'
import Footer from '../../components/Footer'

const MedicoLegal = () => {
    return (
        <div>
            <NavigationBar />
            <CommonHeroSection image={Legal} title="Medico - Legal" />
            <div className='m-auto py-[50px] max-w-[1400px] px-[20px] sm:px-[40px]'>
                <h1 style={{ color: '#424656' }} className='text-center text-[24px] md:text-[32px] tracking-[0.01em] font-semibold'>Medico-Legal Record Services</h1>
                <div className='flex flex-col gap-[40px] md:flex-row mt-[30px] md:mt-[50px]'>
                    <div className='w-[100%] md:w-[60%]'>
                        <p className='text-[16px] md:text-[18px] mt-[5px] font-medium'>Unlock the Potential of Your Medico-Legal Cases with Our Expert Team</p>
                        <p className='text-[14px] md:text-[16px] mt-[3px]'>Experience the exceptional support and expertise of our medico-legal record services. At Care-MD, we take pride in our highly skilled and experienced team of professionals dedicated to processing medical records for a wide range of cases, including Personal Injury, Workers Compensation, Medical Malpractice, and Mass Tort.</p>

                        <p className='text-[16px] md:text-[18px] mt-[15px] font-medium'>Efficiency, Competence, and Evidentiary-Based Solutions</p>
                        <p className='text-[14px] md:text-[16px] mt-[3px]'>We understand the unique demands of the medico-legal industry, which is why we offer efficient, competent, and evidentiary-based services. Our team of doctors, nurses, and legal experts are well-versed in providing invaluable support tailored to the specific needs of your cases. From creating meticulous chronological reports of medical records to offering comprehensive medical opinions and preparing settlement letters, we are committed to delivering exceptional medico-legal services.</p>

                        <p className='text-[16px] md:text-[18px] mt-[15px] font-medium'>Harness the Power of Comprehensive Medico-Legal Support</p>
                        <p className='text-[14px] md:text-[16px] mt-[3px]'>Our medico-legal services extend beyond the basics. We go the extra mile to ensure that our solutions meet the highest standards of quality and accuracy. By leveraging our expertise, you can benefit from a range of important medical tasks performed by our skilled team. We strive to provide you with the support you need, precisely when you need it.</p>

                        <p className='text-[16px] md:text-[18px] mt-[15px] font-medium'>Trustworthy and Confidential</p>
                        <p className='text-[14px] md:text-[16px] mt-[3px]'>Confidentiality and trust are paramount in the medico-legal field. Rest assured, your sensitive information is in safe hands. We uphold strict confidentiality protocols and adhere to all legal and ethical standards. You can rely on our team to handle your medico-legal records with the utmost care and discretion.</p>

                        <p className='text-[16px] md:text-[18px] mt-[15px] font-medium'>Client-Centric Approach</p>
                        <p className='text-[14px] md:text-[16px] mt-[3px]'>Your success is our priority. We take a client-centric approach, tailoring our services to meet your unique requirements. Our team is dedicated to building long-term partnerships based on trust, reliability, and exceptional service. By choosing Care-MD, you can expect personalized attention and a commitment to exceeding your expectations.</p>

                        <p className='text-[16px] md:text-[18px] mt-[15px] font-medium'>Experience the Difference with Care-MD</p>
                        <p className='text-[14px] md:text-[16px] mt-[3px]'>When it comes to medico-legal record services, choose Care-MD to unlock the full potential of your cases. Our professional team is here to support you with efficiency, competence, and evidence-based solutions. Let us help you strengthen your medico-legal cases and achieve the best possible outcomes. Contact us today to discover the Care-MD advantage.</p>
                    </div>
                    <div className='image-container w-[100%] md:w-[40%]'>
                        <div className="image-content" style={{ backgroundImage: `url(${Legal1})` }} />
                    </div>
                </div>
            </div>
            <Contact />
            <Footer />
        </div>
    )
}

export default MedicoLegal