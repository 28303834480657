import React from 'react'
import './HeroSection.css'
import Hipaa from '../../../assets/hipaa.png'
import ISO from '../../../assets/iso.png'
import { motion } from 'framer-motion'
import { NavLink } from 'react-router-dom'

const HeroSection = () => {
    return (
        <div className='backgroundImages'>
            <div className='absolute right-[30px] top-[30px] flex z-10'>
                <motion.img
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.5 }}
                    style={{ backgroundColor: '#fff' }}
                    className='w-[60px] md:w-[70px] h-auto rounded-[50%] p-[5px]'
                    src={Hipaa}
                    alt=""
                />
                <motion.img
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                    style={{ backgroundColor: '#fff' }}
                    className='w-[60px] md:w-[70px] h-auto ml-[20px] rounded-[50%] p-[5px]'
                    src={ISO}
                    alt=""
                />
            </div>

            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                className='z-10 absolute bottom-0 p-[20px] md:p-[30px] text-white md:right-[200px] md:max-w-[600px] md:rounded-t-[5px]'
                style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
                <h1 className='text-[18px] font-semibold sm:text-[28px]'>Care-MD Business Support Services</h1>
                <p className='my-[20px] md:my-[30px] text-[14px] sm:text-[16px]'>Our team stays up-to-date with the latest industry changes and can handle all your business support needs with ease</p>
                <NavLink to="/contact">
                    <button className='flex py-[8px] px-[15px] rounded-[5px] text-[14px] items-center sm:text-[16px] sm:py-[12px] sm:px-[25px]' style={{ backgroundColor: '#3f72cb' }}>
                        <svg className='w-[18px] md:w-[24px] mr-[10px]' width="24px" height="24px" strokeWidth="2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000">
                            <path d="M18.118 14.702L14 15.5c-2.782-1.396-4.5-3-5.5-5.5l.77-4.13L7.815 2H4.064c-1.128 0-2.016.932-1.847 2.047.42 2.783 1.66 7.83 5.283 11.453 3.805 3.805 9.286 5.456 12.302 6.113 1.165.253 2.198-.655 2.198-1.848v-3.584l-3.882-1.479z" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        REACH OUT TO US  -  24/7
                    </button>
                </NavLink>
            </motion.div>
        </div>
    )
}

export default HeroSection