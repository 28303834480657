import React from 'react'
import NavigationBar from '../../components/NavigationBar'
import CommonHeroSection from '../../components/CommonHeroSection'
import Records from '../../assets/records.jpg'
import Contact from '../../components/homeSectionComponents/contact/Contact'
import Footer from '../../components/Footer'
import CustomizedSolutionImage from '../../assets/customizedSolution.jpg'
import CustomizedSolutionImage1 from '../../assets/customizedSolution1.jpg'

const CustomizedSolution = () => {
    return (
        <div>
            <NavigationBar />
            <CommonHeroSection image={Records} title="Customized Solution" />
            <div className='m-auto py-[50px] max-w-[1400px] px-[20px] sm:px-[40px]'>
                <h1 style={{ color: '#424656' }} className='text-[20px] md:text-[24px] tracking-[0.01em] font-semibold'>Care-MD: Your Trusted Partner for Customized Business Service Solutions</h1>
                <p className='text-[14px] md:text-[16px] mt-[5px]'>At Care-MD, we recognize that each practice is unique and requires tailored solutions to meet its specific needs. Our commitment is to provide you with customized business service solutions that align with your goals and priorities. With our expertise and dedication, we work closely with you to understand your practice's intricacies and design solutions that deliver optimal results.</p>

                <div className='flex flex-col gap-[40px] md:flex-row mt-[30px] md:mt-[50px]'>
                    <div className='w-[100%] md:w-[60%]'>
                        <h1 style={{ color: '#424656' }} className='text-[20px] md:text-[24px] tracking-[0.01em] font-semibold'>Why Choose Care-MD for Customized Business Service Solutions</h1>
                        <p className='text-[14px] md:text-[16px] mt-[5px]'><span className='font-medium text-[15px] md:text-[17px]'>Comprehensive Assessment :</span> Our team of experts conducts a comprehensive assessment of your practice, taking into account factors such as size, specialty, workflow, and specific requirements. This assessment allows us to gain a deep understanding of your unique needs and challenges, ensuring that the solutions we offer are specifically tailored to address them.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'><span className='font-medium text-[15px] md:text-[17px]'>Strategic Planning :</span> Based on the assessment, we develop a strategic plan that outlines the customized solutions for your practice. Our experienced professionals collaborate with you to identify areas of improvement, streamline processes, enhance efficiency, and maximize productivity. This strategic approach ensures that our solutions align seamlessly with your practice's objectives.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'><span className='font-medium text-[15px] md:text-[17px]'>Advanced Technology Integration :</span> Care-MD leverages cutting-edge technology and software solutions to support your customized business service needs. We carefully select and integrate the most appropriate tools and systems into your existing infrastructure, optimizing workflows and automating processes. By harnessing technology, we empower your practice to operate more efficiently and effectively.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'><span className='font-medium text-[15px] md:text-[17px]'>Continuous Collaboration :</span> Our partnership does not end with the implementation of customized solutions. We believe in fostering long-term relationships with our clients, which includes ongoing collaboration and support. Our team remains accessible to address any concerns, provide guidance, and adapt the solutions as your practice evolves and grows.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'><span className='font-medium text-[15px] md:text-[17px]'>Scalability and Flexibility :</span> As your practice evolves, your business service needs may change. Care-MD ensures that our customized solutions are scalable and flexible, capable of accommodating your evolving requirements. Whether you experience growth, changes in patient volume, or shifts in your practice dynamics, we have the agility to adapt and provide solutions that continue to meet your needs.</p>
                    </div>
                    <div className='image-container w-[100%] md:w-[40%]'>
                        <div className="image-content" style={{ backgroundImage: `url(${CustomizedSolutionImage})` }} />
                    </div>
                </div>

                <div className='flex flex-col-reverse gap-[40px] md:flex-row mt-[100px]'>
                    <div className='image-container w-[100%] md:w-[40%]'>
                        <div className="image-content" style={{ backgroundImage: `url(${CustomizedSolutionImage1})` }} />
                    </div>

                    <div className='w-[100%] md:w-[60%]'>
                        <h1 style={{ color: '#424656' }} className='text-[20px] md:text-[24px] tracking-[0.01em] font-semibold'>Experience the Benefits of Customized Solutions with Care-MD</h1>
                        <p className='text-[14px] md:text-[16px] mt-[5px]'>Partnering with Care-MD for customized business service solutions unlocks a range of benefits for your practice. By tailoring our services to your unique needs, we enable you to :</p>
                        <p className='text-[14px] md:text-[16px] mt-[10px]'>Streamline workflows and optimize operational efficiency</p>
                        <p className='text-[14px] md:text-[16px] mt-[10px]'>Reduce administrative burdens and free up valuable time</p>
                        <p className='text-[14px] md:text-[16px] mt-[10px]'>Improve accuracy and compliance in documentation and billing processes</p>
                        <p className='text-[14px] md:text-[16px] mt-[10px]'>Enhance patient satisfaction and experience through seamless interactions</p>
                        <p className='text-[14px] md:text-[16px] mt-[10px]'>Maximize revenue generation and financial performance</p>
                    </div>
                </div>

                <h1 style={{ color: '#424656' }} className='text-[20px] md:text-[24px] tracking-[0.01em] font-semibold mt-[50px]'>Contact Care-MD for Customized Business Service Solutions</h1>
                <p className='text-[14px] md:text-[16px] mt-[5px]'>Discover the power of customized solutions that align perfectly with your practice's requirements. Contact our dedicated team at Care-MD to discuss your needs and explore how our tailored services can transform your operations. Experience the difference of having a trusted partner who puts your practice's success at the forefront.</p>
                <p className='text-[14px] md:text-[16px] mt-[15px]'>Elevate your practice with Care-MD's customized business service solutions. Let us help you achieve operational excellence and deliver exceptional patient care.</p>
            </div>
            <Contact />
            <Footer />
        </div>
    )
}

export default CustomizedSolution