import React from 'react'
import Billing from '../../../assets/billing.jpg'
import { motion } from 'framer-motion'
import { NavLink } from 'react-router-dom'

const AboutUs = () => {
    return (
        <div style={{ backgroundColor: "#F7F9FF" }} id='about'>
            <div className='m-auto py-[50px] max-w-[1400px] px-[20px] sm:px-[40px] flex justify-between items-center flex-col md:flex-row'>
                <motion.div className='w-[100%] md:w-[60%] lg:mr-[20px]'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 0.5 }}
                    variants={{
                        visible: { opacity: 1, y: 0 },
                        hidden: { opacity: 0, y: 100 }
                    }}>
                    <h1 style={{ color: '#424656' }} className='text-[32px] tracking-[0.01em] font-semibold'>About Us</h1>
                    <p className='text-[14px] sm:text-[16px] mt-[10px]'>Welcome to Care-MD, your trusted business support provider in the USA. With 20+ years of experience, we offer a wide range of services including calls, scheduling, medico-legal support, coding, billing, transcription, and more. Maximize revenue, reduce costs, and ensure efficiency with Care-MD. Schedule a free consultation today!</p>
                    <NavLink to="/about">
                        <button style={{ backgroundColor: '#3F72CB' }} className='w-[180px] rounded-[5px] h-[45px] mt-[20px] text-white'>Learn More</button>
                    </NavLink>
                </motion.div>
                <motion.div className='w-[100%] md:w-[40%] mt-[20px] lg:flex'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 0.5 }}
                    variants={{
                        visible: { opacity: 1, y: 0 },
                        hidden: { opacity: 0, y: 100 }
                    }}>
                    <img className="rounded-[5px] object-contain" src={Billing} alt="" />
                </motion.div>
            </div>
        </div>
    )
}

export default AboutUs