import React from 'react'
import Contact from '../../components/homeSectionComponents/contact/Contact'
import Footer from '../../components/Footer'
import CommonHeroSection from '../../components/CommonHeroSection'
import NavigationBar from '../../components/NavigationBar'
import ContactImage from '../../assets/contact.jpg'

const ContactUs = () => {
    return (
        <div>
            <NavigationBar />
            <CommonHeroSection image={ContactImage} title="CONTACT US" />
            <Contact />
            <Footer />
        </div>
    )
}

export default ContactUs