import React from 'react'
import NavigationBar from '../../components/NavigationBar'
import CommonHeroSection from '../../components/CommonHeroSection'
import TeleradiologyImage from '../../assets/Teleradiology.jpg'
import TeleradiologyImage1 from '../../assets/Teleradiology1.jpg'
import Contact from '../../components/homeSectionComponents/contact/Contact'
import Footer from '../../components/Footer'

const Teleradiology = () => {
    return (
        <div>
            <NavigationBar />
            <CommonHeroSection image={TeleradiologyImage} title="TELERADIOLOGY" />
            <div className='m-auto py-[50px] max-w-[1400px] px-[20px] sm:px-[40px]'>
                <div className='flex flex-col gap-[40px] md:flex-row'>
                    <div className='w-[100%] md:w-[60%]'>
                        <h1 style={{ color: '#424656' }} className='text-[20px] md:text-[24px] tracking-[0.01em] font-semibold'>Care-MD: Your Trusted Partner for Exceptional Teleradiology Services</h1>
                        <p className='text-[14px] md:text-[16px] mt-[5px]'>Care-MD stands at the forefront of Teleradiology providers, delivering unparalleled expertise and reliable services in India and the USA. Our team of highly skilled medical experts specializes in the evaluation of various medical screenings, including MRI, CT scans, Ultrasounds, and X-ray studies, providing accurate and transcribed reports.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'>As one of the best Teleradiology services available, we prioritize efficiency and timely delivery. Our streamlined processes enable a quick turnaround time, ensuring that you have access to preliminary and final reports 24/7. We understand the urgency of radiological interpretations and strive to provide swift and reliable results to support your patient care.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'>Quality is our utmost priority, and we maintain a rigorous three-level quality assurance process for all reports. This ensures that every report is thoroughly reviewed and meets the highest standards of accuracy and precision. With Care-MD, you can have full confidence in the reliability and integrity of our radiological interpretations.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'>Our distinguished panel of radiologists comprises both US Board Certified Radiologists and Indian MD Certified Radiologists, offering a blend of global expertise and in-depth knowledge. This comprehensive approach guarantees comprehensive and well-informed interpretations of medical images, providing valuable insights for diagnosis and treatment planning.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'>At Care-MD, we offer a two-level reporting service to further enhance the accuracy and reliability of our reports. Firstly, the images are meticulously analyzed by a Junior and Senior Indian MD-certified radiologist, who generate a preliminary report in the required format. Subsequently, the report undergoes thorough scrutiny as it is reviewed and signed off by a US Board Certified Radiologist. This additional level of scrutiny ensures the highest level of accuracy and confidence in the final report.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'>Partner with Care-MD, and experience the exceptional quality and efficiency of our Teleradiology services. Trust us to deliver accurate and timely radiological interpretations, empowering you to make well-informed clinical decisions and provide the best possible care to your patients.</p>
                    </div>
                    <div className='image-container w-[100%] md:w-[40%]'>
                        <div className="image-content" style={{ backgroundImage: `url(${TeleradiologyImage1})` }} />
                    </div>
                </div>
            </div>
            <Contact />
            <Footer />
        </div>
    )
}

export default Teleradiology