import React, { useState } from 'react'
import axios from 'axios';

const Contact = () => {
    const [message, setMessage] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [sent, setSent] = useState(false)
    const [loading, setLoading] = useState(false)
    const [checkedItems, setCheckedItems] = useState({});

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckedItems((prevCheckedItems) => ({ ...prevCheckedItems, [name]: checked }));
    };

    const sendEmail = async () => {
        try {
            if (sent) return alert("Message sent successfully")

            let selectedServices = Object.keys(checkedItems);
            selectedServices = selectedServices.join(", ");

            const data = {
                service_id: 'service_4w2ztuz',
                template_id: 'template_o6pmsjx',
                user_id: 'pe8zXUhb532pzZY-a',
                template_params: {
                    user_name: firstName + " " + lastName,
                    message: message,
                    companyName: companyName,
                    email: email,
                    services: selectedServices,
                    phone: phone,
                }
            };

            setLoading(true)
            await axios.post('https://api.emailjs.com/api/v1.0/email/send', data)
            setSent(true)
            setFirstName("")
            setLastName("")
            setMessage("")
            setCompanyName("")
            setEmail("")
            setPhone("")
            setCheckedItems({})
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    return (
        <div style={{ backgroundColor: "#F7F9FF" }} id="contact">
            <div className='m-auto py-[50px] max-w-[1400px] px-[20px] sm:px-[40px]'>
                <div className='flex flex-col md:flex-row'>
                    <div className='w-[100%]'>
                        <h1 style={{ color: '#424656' }} className='text-[20px] md:text-[24px] tracking-[0.01em] font-semibold'>Submit your details for a Call Back</h1>
                        <p className='text-[14px] md:text-[16px]'>Partner with Care-MD today and experience the peace of mind that comes with efficient and reliable business support services. Contact us today to learn more about our services and how we can help your practice.</p>
                    </div>

                    <div className='w-[100%] mt-[30px] md:ml-[50px] md:mt-0'>
                        <div className='w-full flex gap-[15px]'>
                            <input value={firstName} onChange={(e) => setFirstName(e.target.value)} className='text-[14px] md:text-[16px] w-full h-[40px] rounded-[5px] px-[10px]' style={{ border: '1.5px solid #828282' }} type='text' placeholder='Your First Name' />
                            <input value={lastName} onChange={(e) => setLastName(e.target.value)} className='text-[14px] md:text-[16px] w-full h-[40px] rounded-[5px] px-[10px]' style={{ border: '1.5px solid #828282' }} type='text' placeholder='Your Last Name' />
                        </div>

                        <input value={companyName} onChange={(e) => setCompanyName(e.target.value)} className='text-[14px] md:text-[16px] w-full h-[40px] rounded-[5px] px-[10px] mt-[15px]' style={{ border: '1.5px solid #828282' }} type='text' placeholder='Company Name' />
                        <input value={email} onChange={(e) => setEmail(e.target.value)} className='text-[14px] md:text-[16px] w-full h-[40px] rounded-[5px] px-[10px] mt-[15px]' style={{ border: '1.5px solid #828282' }} type='email' placeholder='Your Email' />
                        <input value={phone} onChange={(e) => setPhone(e.target.value)} className='text-[14px] md:text-[16px] w-full h-[40px] rounded-[5px] px-[10px] mt-[15px]' style={{ border: '1.5px solid #828282' }} type='text' placeholder='Your Phone' />

                        <div className='flex flex-wrap mt-[15px] gap-x-[20px] gap-y-[10px]'>
                            <label className='flex items-center text-[14px] md:text-[16px]'>
                                <input
                                    type="checkbox"
                                    className='mr-[10px] h-[18px] w-[18px] cursor-pointer'
                                    style={{ border: '1.5px solid #828282' }}
                                    name="APPOINTMENT SCHEDULING & CALL CENTER"
                                    checked={checkedItems['APPOINTMENT SCHEDULING & CALL CENTER'] || false}
                                    onChange={handleCheckboxChange}
                                />
                                APPOINTMENT SCHEDULING & CALL CENTER
                            </label>

                            <label className='flex items-center text-[14px] md:text-[16px]'>
                                <input
                                    type="checkbox"
                                    className='mr-[10px] h-[18px] w-[18px] cursor-pointer'
                                    style={{ border: '1.5px solid #828282' }}
                                    name="MEDICAL BILLING & CODING"
                                    checked={checkedItems['MEDICAL BILLING & CODING'] || false}
                                    onChange={handleCheckboxChange}
                                />
                                MEDICAL BILLING & CODING
                            </label>

                            <label className='flex items-center text-[14px] md:text-[16px]'>
                                <input
                                    type="checkbox"
                                    className='mr-[10px] h-[18px] w-[18px] cursor-pointer'
                                    style={{ border: '1.5px solid #828282' }}
                                    name="ACCOUNTS RECEIVABLE SERVICES"
                                    checked={checkedItems['ACCOUNTS RECEIVABLE SERVICES'] || false}
                                    onChange={handleCheckboxChange}
                                />
                                ACCOUNTS RECEIVABLE SERVICES
                            </label>

                            <label className='flex items-center text-[14px] md:text-[16px]'>
                                <input
                                    type="checkbox"
                                    className='mr-[10px] h-[18px] w-[18px] cursor-pointer'
                                    style={{ border: '1.5px solid #828282' }}
                                    name="REVENUE CYCLE MANAGEMENT"
                                    checked={checkedItems['REVENUE CYCLE MANAGEMENT'] || false}
                                    onChange={handleCheckboxChange}
                                />
                                REVENUE CYCLE MANAGEMENT
                            </label>

                            <label className='flex items-center text-[14px] md:text-[16px]'>
                                <input
                                    type="checkbox"
                                    className='mr-[10px] h-[18px] w-[18px] cursor-pointer'
                                    style={{ border: '1.5px solid #828282' }}
                                    name="MEDICAL SCRIBE"
                                    checked={checkedItems['MEDICAL SCRIBE'] || false}
                                    onChange={handleCheckboxChange}
                                />
                                MEDICAL SCRIBE
                            </label>

                            <label className='flex items-center text-[14px] md:text-[16px]'>
                                <input
                                    type="checkbox"
                                    className='mr-[10px] h-[18px] w-[18px] cursor-pointer'
                                    style={{ border: '1.5px solid #828282' }}
                                    name="MEDICAL RECORDS REVIEW"
                                    checked={checkedItems['MEDICAL RECORDS REVIEW'] || false}
                                    onChange={handleCheckboxChange}
                                />
                                MEDICAL RECORDS REVIEW
                            </label>

                            <label className='flex items-center text-[14px] md:text-[16px]'>
                                <input
                                    type="checkbox"
                                    className='mr-[10px] h-[18px] w-[18px] cursor-pointer'
                                    style={{ border: '1.5px solid #828282' }}
                                    name="MEDICO – LEGAL"
                                    checked={checkedItems['MEDICO – LEGAL'] || false}
                                    onChange={handleCheckboxChange}
                                />
                                MEDICO – LEGAL
                            </label>

                            <label className='flex items-center text-[14px] md:text-[16px]'>
                                <input
                                    type="checkbox"
                                    className='mr-[10px] h-[18px] w-[18px] cursor-pointer'
                                    style={{ border: '1.5px solid #828282' }}
                                    name="TELERADIOLOGY"
                                    checked={checkedItems['TELERADIOLOGY'] || false}
                                    onChange={handleCheckboxChange}
                                />
                                TELERADIOLOGY
                            </label>
                        </div>

                        <textarea value={message} onChange={(e) => setMessage(e.target.value)} placeholder='Your Messsage' className='text-[14px] md:text-[16px] w-full h-[150px] rounded-[5px] p-[10px] mt-[15px] resize-none' style={{ border: '1.5px solid #828282' }}></textarea>
                        <button onClick={sendEmail} className='text-[14px] md:text-[16px] w-[180px] h-[45px] rounded-[5px] text-white mt-[20px] flex justify-center items-center' style={{ backgroundColor: '#3f72cb' }}>
                            {loading ? "Sending . . ." : sent ? "Sent" : "Submit"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact