import React, { useState } from 'react'

const Faq = () => {
    const [activeQuestion, setActiveQuestion] = useState(null)

    const interviewDialogue = [
        {
            question: "How does Care-MD handle inbound/outbound calls for our organization?",
            answer: "Care-MD: At Care-MD, we understand the significance of effective communication in providing excellent customer service. We have a dedicated team of professionals trained to handle inbound/outbound calls on behalf of your organization. Whether it's scheduling appointments, addressing inquiries, or following up on important matters, our team ensures prompt and courteous communication, representing your organization in the best possible light."
        },
        {
            question: "Can you explain how Care-MD manages scheduling and rescheduling processes?",
            answer: "Care-MD: Scheduling and rescheduling can be time-consuming and challenging tasks for any organization. We offer comprehensive scheduling and rescheduling services to streamline these processes. Our team utilizes efficient systems and technologies to manage appointments, ensuring accurate and up-to-date schedules. By entrusting us with scheduling and rescheduling responsibilities, you can optimize your time management and maintain a well-organized calendar."
        },
        {
            question: "How does Care-MD support medico-legal services, specifically in workers' compensation cases?",
            answer: "Care-MD: We have specialized expertise in providing medico-legal services, particularly for workers' compensation cases. Our professionals are well-versed in the specific requirements and regulations of workers' compensation billing and documentation. We ensure accurate and timely reporting, comprehensive documentation, and proper coding to support your organization in navigating the complexities of workers' compensation cases."
        },
        {
            question: "Can you elaborate on Care-MD's medical coding services?",
            answer: "Care-MD: Medical coding is a crucial aspect of healthcare administration and revenue cycle management. Our team of highly skilled and certified medical coders excels in assigning the appropriate codes to diagnoses, procedures, and services, ensuring accurate and compliant billing. With our expertise in various coding systems and extensive knowledge of medical terminology, we help maximize reimbursement and minimize coding errors for your organization."
        },
        {
            question: "How does Care-MD handle medical billing processes?",
            answer: "Care-MD: Medical billing is a complex and time-sensitive task. Our dedicated billing team is proficient in managing the end-to-end medical billing process. We handle claims submission, follow-up on outstanding payments, and manage denials and appeals. Our goal is to optimize revenue and minimize billing errors, ensuring a smooth and efficient billing workflow for your organization."
        },
        {
            question: "Can you explain how Care-MD supports medical transcription services?",
            answer: "Care-MD: Accurate and timely medical transcription is vital for maintaining comprehensive patient records. Our skilled medical transcriptionists transcribe dictated medical reports, notes, and other documentation with precision and attention to detail. We prioritize accuracy and adherence to industry standards, ensuring that your medical records are reliably transcribed, contributing to effective patient care and seamless communication among healthcare providers."
        },
        {
            question: "How does Care-MD assist with medical records review?",
            answer: "Care-MD: Medical records review is essential for quality assurance and compliance purposes. Our team of professionals conducts thorough reviews of medical records, ensuring completeness, accuracy, and compliance with regulations. We identify any gaps or discrepancies, provide comprehensive reports, and offer insights to improve documentation practices, supporting your organization in maintaining high standards of care."
        },
        {
            question: "What services does Care-MD offer in teleradiology?",
            answer: "Care-MD: Teleradiology is a critical component of modern healthcare, enabling remote interpretation and consultation of radiological images. Care-MD offers teleradiology services, connecting healthcare providers with experienced radiologists who can accurately interpret and provide diagnostic insights on various imaging studies. This service facilitates prompt and efficient diagnosis, contributing to improved patient care and collaboration among healthcare professionals."
        }
    ];

    const toggleActiveQuestion = (index) => {
        if (activeQuestion === index) {
            setActiveQuestion(null)
        } else {
            setActiveQuestion(index)
        }
    }

    return (
        <div className='m-auto py-[50px] max-w-[1400px] px-[20px] sm:px-[40px]'>
            <h1 style={{ color: '#424656' }} className='text-[32px] tracking-[0.01em] font-semibold'>FAQ</h1>

            {
                interviewDialogue.map((data, index) => {
                    return <div
                        style={{ backgroundColor: '#E6F4F1' }}
                        onClick={() => toggleActiveQuestion(index)}
                        className='bg-white shadow p-4 mt-[20px] rounded-[5px] cursor-pointer' key={index}>
                        <div className='flex justify-between'>
                            <p className='text-[14px] md:text-[16px]'>{data.question}</p>
                            {
                                activeQuestion === index ?
                                    <svg width="24px" height="24px" strokeWidth="2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000">
                                        <path d="M6 12h12" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    :
                                    <svg width="24px" height="24px" strokeWidth="2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000">
                                        <path d="M6 12h6m6 0h-6m0 0V6m0 6v6" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                            }
                        </div>
                        {
                            activeQuestion === index ? <p style={{backgroundColor:'#F7F9FF'}}  className='text-[14px] md:text-[16px] mt-[10px] p-4 rounded-[5px]'>{data.answer}</p> : null
                        }
                    </div>
                })
            }
        </div>
    )
}

export default Faq