import React from 'react'
import NavigationBar from '../../components/NavigationBar'
import HeroSection from '../../components/homeSectionComponents/hero/HeroSection'
import Footer from '../../components/Footer'
import Services from '../../components/homeSectionComponents/ourServices/Services'
import AboutUs from '../../components/homeSectionComponents/about/AboutUs'
import Experties from '../../components/homeSectionComponents/experties/Experties'
import Pricing from '../../components/homeSectionComponents/pricing/Pricing'
import SolutionAndSecurity from '../../components/homeSectionComponents/solutionAndSecurity/SolutionAndSecurity'
import Contact from '../../components/homeSectionComponents/contact/Contact'

const Home = () => {
  return (
    <main>
        <NavigationBar />
        <HeroSection />
        <Services />
        <AboutUs />
        <Pricing />
        <Experties />
        <SolutionAndSecurity />
        <Contact />
        <Footer />
    </main>
  )
}

export default Home