import React from 'react'
import PricingImage from '../../../assets/pricing.jpg'
import { NavLink } from 'react-router-dom'

const Pricing = () => {
    return (
        <div style={{ backgroundColor: "#F7F9FF" }} id='pricing'>
            <div className='m-auto py-[50px] max-w-[1400px] px-[20px] sm:px-[40px] flex justify-between items-center flex-col-reverse gap-[20px] md:flex-row'>
                <div className='w-[100%] md:w-[40%] mt-[20px] lg:flex'>
                    <img className="rounded-[5px] object-contain" src={PricingImage} alt="" />
                </div>
                <div className='w-[100%] md:w-[60%] lg:ml-[40px]'>
                    <h1 style={{ color: '#424656' }} className='text-[32px] tracking-[0.01em] font-semibold'>Pricing</h1>
                    <p className='text-[14px] sm:text-[16px] my-[10px]'>We provide cutting edge solutions for medical billing and coding with high standard and expert support. In summary, partnering with Care-MD will give you more time to focus on patient care, increase your revenue by ensuring that you get paid what you're owed, and provide you with the peace of mind that comes with knowing your patient data is safe and secure. Our fully integrated services and customized solutions will make the transition seamless and efficient, allowing you to enjoy the benefits of our services without any disruptions to your daily workflow.</p>
                    <NavLink to="/contact">
                        <button className='flex py-[8px] text-white px-[15px] rounded-[5px] text-[14px] items-center sm:text-[16px] sm:py-[12px] sm:px-[25px] mt-[20px]' style={{ backgroundColor: '#3f72cb' }}>
                            <svg className='w-[18px] md:w-[24px] mr-[10px]' width="24px" height="24px" strokeWidth="2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000">
                                <path d="M18.118 14.702L14 15.5c-2.782-1.396-4.5-3-5.5-5.5l.77-4.13L7.815 2H4.064c-1.128 0-2.016.932-1.847 2.047.42 2.783 1.66 7.83 5.283 11.453 3.805 3.805 9.286 5.456 12.302 6.113 1.165.253 2.198-.655 2.198-1.848v-3.584l-3.882-1.479z" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            REACH OUT TO US
                        </button>
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default Pricing