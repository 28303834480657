import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../assets/logo.png'
import USFlag from '../assets/usflag.png'
import { useLocation } from 'react-router-dom';

const NavigationBar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [hideTimeout, setHideTimeout] = useState(null);
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top when the route changes
    }, [location]);

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };

    const handleMouseEnter = () => {
        clearTimeout(hideTimeout);
        setShowDropdown(true);
    };

    const handleMouseLeave = () => {
        const timeout = setTimeout(() => {
            setShowDropdown(false);
        }, 200); // Set the delay (in milliseconds) before hiding the dropdown
        setHideTimeout(timeout);
    };

    return (
        <div style={{ backgroundColor: '#3f72cb' }} className='fixed top-0 w-full z-20'>
            <div className='m-auto max-w-[1400px] px-[10px] md:px-[40px]'>
                <div className='md:flex justify-between items-center pt-[15px]  hidden'>
                    <div className="flex-shrink-0 flex items-center">
                        <NavLink to="/">
                            <img
                                className="block h-[50px] md:h-[60px] w-auto"
                                src={Logo}
                                alt="Logo" />
                        </NavLink>
                    </div>


                    <div className='flex'>
                        <div className='flex items-center'>
                            <svg className='w-[18px] md:w-[24px]' strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000">
                                <path d="M7 9l5 3.5L17 9" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M2 17V7a2 2 0 012-2h16a2 2 0 012 2v10a2 2 0 01-2 2H4a2 2 0 01-2-2z" stroke="#FFFFFF" strokeWidth="1.5" />
                            </svg>
                            <p className='text-[14px] sm:text-[16px] text-white ml-[8px]'>info@care-md.com</p>
                        </div>

                        <div className='flex items-center ml-[30px]'>
                            <svg className='w-[18px] md:w-[24px]' width="24px" height="24px" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000">
                                <path d="M18.118 14.702L14 15.5c-2.782-1.396-4.5-3-5.5-5.5l.77-4.13L7.815 2H4.064c-1.128 0-2.016.932-1.847 2.047.42 2.783 1.66 7.83 5.283 11.453 3.805 3.805 9.286 5.456 12.302 6.113 1.165.253 2.198-.655 2.198-1.848v-3.584l-3.882-1.479z" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <span className='text-[14px] sm:text-[16px] text-white ml-[8px]'>+1-888-802-8202</span>
                        </div>

                        <div className='flex items-center ml-[30px]'>
                            <span className='text-[14px] sm:text-[16px] text-white mr-[8px]'>A US company</span>
                            <img
                                className="block w-[18px] md:w-[24px] h-auto"
                                src={USFlag}
                                alt="Logo" />
                        </div>
                    </div>
                </div>
                <nav className='py-[30px] pb-[30px] md:pb-[20px] md:py-[20px]'>
                    <div>
                        <div className="relative flex items-center justify-between">
                            <div className="flex items-center justify-between w-[100%] md:hidden">
                                <NavLink to="/">
                                    <img
                                        className="block h-[40px] md:h-[60px] w-auto"
                                        src={Logo}
                                        alt="Logo" />
                                </NavLink>

                                <div className='flex items-center'>
                                    <span className='text-[12px] text-white'>A US company</span>
                                    <img
                                        className="block w-[14px] h-auto ml-[5px]"
                                        src={USFlag}
                                        alt="Logo" />
                                </div>

                                <button
                                    onClick={toggleNavbar}
                                    className="inline-flex items-center justify-center p-[4px] rounded-[5px] outline-none ring-1 ring-inset ring-white">
                                    <span className="sr-only">Open main menu</span>
                                    {isOpen ? (
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6 18L18 6M6 6L18 18" stroke="#FFF" />
                                        </svg>
                                    ) : (
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4 6H20M4 12H20M4 18H20" stroke="#FFF" />
                                        </svg>
                                    )}
                                </button>
                            </div>
                            <div className="md:flex items-center justify-between w-full hidden">
                                <div className="hidden md:block">
                                    <div className="flex space-x-5">
                                        <NavLink to="/" className={`text-white home ${location.pathname === '/' ? "font-semibold drop-shadow-md" : ""}`}>Home</NavLink>
                                        <NavLink to="/about" className={`text-white about ${location.pathname === '/about' ? "font-semibold drop-shadow-md" : ""}`}>About</NavLink>
                                        <div className="relative group" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                            <button className='text-white flex'>
                                                Services
                                                <svg className='w-[20px] ml-[2px]' width="24px" height="24px" strokeWidth="2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#FFFFFF">
                                                    <path d="M6 9l6 6 6-6" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </button>
                                            <div className={`absolute left-0 mt-2 w-[400px] bg-white rounded-[3px] shadow-lg ${showDropdown ? 'block' : 'hidden'}`}>
                                                <NavLink to="/appointment-scheduling-and-call-center" className={`block px-4 py-2 pt-4 text-sm text-gray-700 ${location.pathname === '/appointment-scheduling-and-call-center' ? "font-semibold" : ""}`}>APPOINTMENT SCHEDULING & CALL CENTER</NavLink>
                                                <NavLink to="/medical-billing-and-coding" className={`block px-4 py-2 text-sm text-gray-700 ${location.pathname === '/medical-billing-and-coding' ? "font-semibold" : ""}`}>MEDICAL BILLING & CODING</NavLink>
                                                <NavLink to="/accounts-receivable-services" className={`block px-4 py-2 text-sm text-gray-700 ${location.pathname === '/accounts-receivable-services' ? "font-semibold" : ""}`}>ACCOUNTS RECEIVABLE SERVICES</NavLink>
                                                <NavLink to="/revenue-cycle-management" className={`block px-4 py-2 text-sm text-gray-700 ${location.pathname === '/revenue-cycle-management' ? "font-semibold" : ""}`}>REVENUE CYCLE MANAGEMENT</NavLink>
                                                <NavLink to="/medical-scribe-services" className={`block px-4 py-2 text-sm text-gray-700 ${location.pathname === '/medical-scribe-services' ? "font-semibold" : ""}`}>MEDICAL SCRIBE</NavLink>
                                                <NavLink to="/medical-records-review" className={`block px-4 py-2 text-sm text-gray-700 ${location.pathname === '/medical-records-review' ? "font-semibold" : ""}`}>MEDICAL RECORDS REVIEW</NavLink>
                                                <NavLink to="/medico-legal" className={`block px-4 py-2 text-sm text-gray-700 ${location.pathname === '/medico-legal' ? "font-semibold" : ""}`}>MEDICO - LEGAL</NavLink>
                                                <NavLink to="/teleradiology" className={`block px-4 py-2 pb-4 text-sm text-gray-700 ${location.pathname === '/teleradiology' ? "font-semibold" : ""}`}>TELERADIOLOGY</NavLink>
                                            </div>
                                        </div>
                                        <NavLink to="/pricing" className={`text-white ${location.pathname === '/pricing' ? "font-semibold drop-shadow-md" : ""}`}>Pricing</NavLink>
                                        <NavLink to="/customized-solution" className={`text-white ${location.pathname === '/customized-solution' ? "font-semibold drop-shadow-md" : ""}`}>Customized Solutions</NavLink>
                                        <NavLink to="/faq" className={`text-white ${location.pathname === '/faq' ? "font-semibold drop-shadow-md" : ""}`}>FAQ</NavLink>
                                    </div>
                                </div>

                                <NavLink to="/contact" className="text-white">
                                    <button className='text-white py-[10px] px-[40px] rounded-[3px] tracking-[0.02em] hidden md:flex' style={{ backgroundColor: '#7e9dd4d2' }}>Contact Us</button>
                                </NavLink>
                            </div>
                        </div>
                    </div>

                    {/* Mobile Menu */}
                    {isOpen && (
                        <div className="md:hidden">
                            <div className="space-y-4 flex flex-col mt-[40px] text-[14px]">
                                <NavLink to="/" className={`text-white home ${location.pathname === '/' ? "font-semibold drop-shadow-md" : ""}`}>Home</NavLink>
                                <NavLink to="/about" className={`text-white home ${location.pathname === '/about' ? "font-semibold drop-shadow-md" : ""}`}>About</NavLink>
                                <div
                                    className="relative group"
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}>
                                    <button className='text-white flex'>
                                        Services
                                        <svg className='w-[20px] ml-[2px]' width="24px" height="24px" strokeWidth="2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#FFFFFF">
                                            <path d="M6 9l6 6 6-6" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </button>
                                    <div className={`z-10 absolute left-0 mt-2 w-[250px] bg-white rounded-[5px] shadow-lg ${showDropdown ? 'block' : 'hidden'}`}>
                                        <div className={`absolute left-0 mt-2 w-[400px] bg-white rounded-[3px] shadow-lg ${showDropdown ? 'block' : 'hidden'}`}>
                                            <NavLink to="/appointment-scheduling-and-call-center" className={`block px-4 py-2 pt-4 text-[12px] text-gray-700 ${location.pathname === '/appointment-scheduling-and-call-center' ? "font-semibold" : ""}`}>APPOINTMENT SCHEDULING & CALL CENTER</NavLink>
                                            <NavLink to="/medical-billing-and-coding" className={`block px-4 py-2 text-[12px] text-gray-700 ${location.pathname === '/medical-billing-and-coding' ? "font-semibold" : ""}`}>MEDICAL BILLING & CODING</NavLink>
                                            <NavLink to="/accounts-receivable-services" className={`block px-4 py-2 text-[12px] text-gray-700 ${location.pathname === '/accounts-receivable-services' ? "font-semibold" : ""}`}>ACCOUNTS RECEIVABLE SERVICES</NavLink>
                                            <NavLink to="/revenue-cycle-management" className={`block px-4 py-2 text-[12px] text-gray-700 ${location.pathname === '/revenue-cycle-management' ? "font-semibold" : ""}`}>REVENUE CYCLE MANAGEMENT</NavLink>
                                            <NavLink to="/medical-scribe-services" className={`block px-4 py-2 text-[12px] text-gray-700 ${location.pathname === '/medical-scribe-services' ? "font-semibold" : ""}`}>MEDICAL SCRIBE</NavLink>
                                            <NavLink to="/medical-records-review" className={`block px-4 py-2 text-[12px] text-gray-700 ${location.pathname === '/medical-records-review' ? "font-semibold" : ""}`}>MEDICAL RECORDS REVIEW</NavLink>
                                            <NavLink to="/medico-legal" className={`block px-4 py-2 text-[12px] text-gray-700 ${location.pathname === '/medico-legal' ? "font-semibold" : ""}`}>MEDICO - LEGAL</NavLink>
                                            <NavLink to="/teleradiology" className={`block px-4 py-2 pb-4 text-[12px] text-gray-700 ${location.pathname === '/teleradiology' ? "font-semibold" : ""}`}>TELERADIOLOGY</NavLink>
                                        </div>
                                    </div>
                                </div>
                                <NavLink to="/pricing" className={`text-white ${location.pathname === '/pricing' ? "font-semibold drop-shadow-md" : ""}`}>Pricing</NavLink>
                                <NavLink to="/customized-solution" className={`text-white ${location.pathname === '/customized-solution' ? "font-semibold drop-shadow-md" : ""}`}>Customized Solutions</NavLink>
                                <NavLink to="/faq" className={`text-white ${location.pathname === '/faq' ? "font-semibold drop-shadow-md" : ""}`}>FAQ</NavLink>
                                <NavLink to="/contact" className={`text-white ${location.pathname === '/contact' ? "font-semibold drop-shadow-md" : ""}`}>Contact Us</NavLink>
                            </div>
                        </div>
                    )}
                </nav>
            </div>
        </div>
    );
};

export default NavigationBar;
