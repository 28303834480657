import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/home/Home';
import RevenuCycleManagement from './pages/revenueCycleManagement/RevenuCycleManagement';
import MedicoLegal from './pages/medicoLegal/MedicoLegal';
import MedicalRecordReview from './pages/medicalRecordReview/MedicalRecordReview';
import MedicalScribe from './pages/medicalScribe/MedicalScribe';
import Teleradiology from './pages/teleradiology/Teleradiology';
import AccountsReceivableServices from './pages/accountsReceivableServices/AccountsReceivableServices';
import About from './pages/about/About';
import Pricing from './pages/pricing/Pricing';
import CustomizedSolution from './pages/customizedSolution/CustomizedSolution';
import MedicalBillingAndCoadingServices from './pages/medicalBillingAndCoadingServices/MedicalBillingAndCoadingServices';
import ContactUs from './pages/contact/ContactUs';
import FAQPage from './pages/faq/FAQPage';
import AppointmentSchedulingServicesAndCallCenter from './pages/appointmentSchedulingServicesAndCallCenter/AppointmentSchedulingServicesAndCallCenter';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/revenue-cycle-management" element={<RevenuCycleManagement />} />
        <Route path="/medico-legal" element={<MedicoLegal />} />
        <Route path="/medical-records-review" element={<MedicalRecordReview />} />
        <Route path="/medical-scribe-services" element={<MedicalScribe />} />
        <Route path='/teleradiology' element={<Teleradiology />} />
        <Route path='/accounts-receivable-services' element={<AccountsReceivableServices />} />
        <Route path='/customized-solution' element={<CustomizedSolution />} />
        <Route path='/faq' element={<FAQPage />} />
        <Route path='/medical-billing-and-coding' element={<MedicalBillingAndCoadingServices />} />
        <Route path='/appointment-scheduling-and-call-center' element={<AppointmentSchedulingServicesAndCallCenter />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App