import React from 'react'
import Logo from '../assets/logo.png'
import { NavLink } from 'react-router-dom'

const Footer = () => {
  return (
    <footer style={{ backgroundColor: '#16224d' }}>
      <div className='m-auto py-[50px] max-w-[1400px] px-[20px] sm:px-[40px]'>
        <div className='grid grid-cols-1 md:grid-cols-3 gap-[40px] pb-[40px]' style={{ borderBottom: "1px solid #dcdcdc" }}>
          <div>
            <img className='w-[200px]' src={Logo} alt='' />
            <p className='text-white text-[14px] md:text-[16px] mt-[10px]'>Don't miss out on this opportunity to revolutionize your organization. Contact us today to schedule your free consultation with our team of experts. Let us show you firsthand how Care-MD can elevate your business, inspire your team, and make your success our top priority. Because at Care-MD, your triumph is what truly matters to us!</p>
          </div>

          <div className='flex flex-col md:items-center'>
            <div className='flex flex-col'>
              <h2 className='text-white text-[18] md:text-[20px] font-semibold tracking-[0.02em]'>Services</h2>
              <NavLink to="/appointment-scheduling-and-call-center" className='text-white text-[14px] md:text-[16px] mt-[10px]'>APPOINTMENT SCHEDULING & CALL CENTER</NavLink>
              <NavLink to="/medical-billing-and-coding" className='text-white text-[14px] md:text-[16px] mt-[10px]'>MEDICAL BILLING & CODING</NavLink>
              <NavLink to="/accounts-receivable-services" className='text-white text-[14px] md:text-[16px] mt-[10px]'>ACCOUNTS RECEIVABLE SERVICES</NavLink>
              <NavLink to="/revenue-cycle-management" className='text-white text-[14px] md:text-[16px] mt-[10px]'>REVENUE CYCLE MANAGEMENT</NavLink>
              <NavLink to="/medical-scribe-services" className='text-white text-[14px] md:text-[16px] mt-[10px]'>MEDICAL SCRIBE</NavLink>
              <NavLink to="/medical-records-review" className='text-white text-[14px] md:text-[16px] mt-[10px]'>MEDICAL RECORDS REVIEW</NavLink>
              <NavLink to="/medico-legal" className='text-white text-[14px] md:text-[16px] mt-[10px]'>MEDICO - LEGAL</NavLink>
              <NavLink to="/teleradiology" className='text-white text-[14px] md:text-[16px] mt-[10px]'>TELERADIOLOGY</NavLink>
            </div>
          </div>

          <div className='flex flex-col md:items-center'>
            <div>
              <h2 className='text-white text-[18] md:text-[20px] font-semibold tracking-[0.02em]'>Care-MD Inc.</h2>

              <div className='flex items-center mt-[10px]'>
                <svg width="24px" height="24px" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000">
                  <path d="M20 10c0 4.418-8 12-8 12s-8-7.582-8-12a8 8 0 1116 0z" stroke="#FFFFFF" strokeWidth="1.5" />
                  <path d="M12 11a1 1 0 100-2 1 1 0 000 2z" fill="#000000" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <p className='text-white text-[14px] md:text-[16px] ml-[10px]'>2950 Unity Dr. Unit 572143 <br />Houston, TX 77056</p>
              </div>

              <div className='flex items-center mt-[15px]'>
                <svg className='w-[18px] md:w-[24px]' width="24px" height="24px" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000">
                  <path d="M18.118 14.702L14 15.5c-2.782-1.396-4.5-3-5.5-5.5l.77-4.13L7.815 2H4.064c-1.128 0-2.016.932-1.847 2.047.42 2.783 1.66 7.83 5.283 11.453 3.805 3.805 9.286 5.456 12.302 6.113 1.165.253 2.198-.655 2.198-1.848v-3.584l-3.882-1.479z" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <p className='text-white text-[14px] md:text-[16px] ml-[10px]'>+1-888-802-8202</p>
              </div>

              <div className='flex items-center mt-[15px]'>
                <svg className='w-[18px] md:w-[24px]' strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000">
                  <path d="M7 9l5 3.5L17 9" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M2 17V7a2 2 0 012-2h16a2 2 0 012 2v10a2 2 0 01-2 2H4a2 2 0 01-2-2z" stroke="#FFFFFF" strokeWidth="1.5" />
                </svg>
                <p className='text-white text-[14px] md:text-[16px] ml-[10px]'>info@care-md.com</p>
              </div>
            </div>
          </div>
        </div>
        <div className='pt-[40px] text-[12px] sm:text-[14px] md:text-[16px] text-center text-white'>Copyrights © 2023. Care-MD. All rights Reserved</div>
      </div>
    </footer>
  )
}

export default Footer