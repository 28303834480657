import React from 'react'
import NavigationBar from '../../components/NavigationBar'
import CommonHeroSection from '../../components/CommonHeroSection'
import Faq1 from '../../assets/faq.jpg'
import Contact from '../../components/homeSectionComponents/contact/Contact'
import Footer from '../../components/Footer'
import Faq from '../../components/homeSectionComponents/faq/Faq'

const FAQPage = () => {
    return (
        <div>
            <NavigationBar />
            <CommonHeroSection image={Faq1} title="FAQ" />
            <Faq />
            <Contact />
            <Footer />
        </div>
    )
}

export default FAQPage