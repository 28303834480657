import React from 'react'

const SolutionAndSecurity = () => {
  return (
    <div className='m-auto py-[50px] max-w-[1400px] px-[20px] sm:px-[40px]' id='solution-security'>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-[40px]'>
        <div className='flex flex-col justify-center items-center p-[15px] rounded-[5px] shadow-lg' style={{ backgroundColor: '#E6F4F1' }}>
          <div className='w-[40px] h-[40px] rounded-full bg-white flex justify-center items-center p-[8px]' style={{ border: '2px solid #CC4721' }}>
            <svg width="24px" height="24px" strokeWidth="2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#CC4721">
              <path d="M3 7.4V3.6a.6.6 0 01.6-.6h5.8a.6.6 0 01.6.6v3.8a.6.6 0 01-.6.6H3.6a.6.6 0 01-.6-.6zM14 20.4v-3.8a.6.6 0 01.6-.6h5.8a.6.6 0 01.6.6v3.8a.6.6 0 01-.6.6h-5.8a.6.6 0 01-.6-.6zM14 12.4V3.6a.6.6 0 01.6-.6h5.8a.6.6 0 01.6.6v8.8a.6.6 0 01-.6.6h-5.8a.6.6 0 01-.6-.6zM3 20.4v-8.8a.6.6 0 01.6-.6h5.8a.6.6 0 01.6.6v8.8a.6.6 0 01-.6.6H3.6a.6.6 0 01-.6-.6z" stroke="#CC4721" strokeWidth="2" />
            </svg>
          </div>
          <h4 className='text-[18px] font-semibold my-[10px]'>Customized Solutions</h4>
          <p className='text-center text-[14px] md:text-[16px]'>Care-MD offers customized solutions tailored to your unique needs. We understand that every practice is different, and we work closely with you to ensure that our services align with your goals and priorities.</p>
        </div>

        <div className='flex flex-col justify-center items-center p-[15px] rounded-[5px] shadow-lg' style={{ backgroundColor: '#E6F4F1' }}>
          <div className='w-[40px] h-[40px] rounded-full bg-white flex justify-center items-center p-[8px]' style={{ border: '2px solid #00246D' }}>
            <svg width="24px" height="24px" viewBox="0 0 24 24" strokeWidth="2" fill="none" xmlns="http://www.w3.org/2000/svg" color="#00246D">
              <path d="M14 21H4a2 2 0 01-2-2V5a2 2 0 012-2h16a2 2 0 012 2v8" stroke="#00246D" strokeWidth="2" strokeLinecap="round" />
              <path d="M2 7h20M5 5.01l.01-.011M8 5.01l.01-.011M11 5.01l.01-.011M21.167 18.5h.233a.6.6 0 01.6.6v2.3a.6.6 0 01-.6.6h-3.8a.6.6 0 01-.6-.6v-2.3a.6.6 0 01.6-.6h.233m3.334 0v-1.75c0-.583-.334-1.75-1.667-1.75s-1.667 1.167-1.667 1.75v1.75m3.334 0h-3.334" stroke="#00246D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
          <h4 className='text-[18px] font-semibold my-[10px]'>Data Security</h4>
          <p className='text-center text-[14px] md:text-[16px]'>Care-MD places a high value on HIPAA compliance and data security. With our secure systems and protocols, you can rest assured that your patient data is safe and confidential</p>
        </div>
      </div>
    </div>
  )
}

export default SolutionAndSecurity