import React from 'react'
import NavigationBar from '../../components/NavigationBar'
import CommonHeroSection from '../../components/CommonHeroSection'
import Accounts1 from '../../assets/accounts1.jpg'
import MedicalBilling3 from '../../assets/medicalBilling3.jpg'
import MedicalBilling5 from '../../assets/medicalBilling5.jpg'
import Contact from '../../components/homeSectionComponents/contact/Contact'
import Footer from '../../components/Footer'

const MedicalBillingAndCoadingServices = () => {
    return (
        <div>
            <NavigationBar />
            <CommonHeroSection image={Accounts1} title="MEDICAL BILLING AND CODING SERVICES" />
            <div className='m-auto py-[50px] max-w-[1400px] px-[20px] sm:px-[40px]'>
                <div className='flex flex-col gap-[40px] md:flex-row'>
                    <div className='w-[100%] md:w-[60%]'>
                        <h1 style={{ color: '#424656' }} className='text-[20px] md:text-[24px] tracking-[0.01em] font-semibold'>Care-MD - Your Trusted Medical Billing & Coding Partner</h1>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'>Welcome to Care-MD, your trusted partner in anesthesiology medical billing. We understand that managing medical billing can be overwhelming, and it can be challenging to stay on top of changing regulations and requirements. That's why we're here to help.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'>With over 20 years of experience in the industry, Care-MD is a Texas-based medical billing company that specializes in providing comprehensive and customized billing services for anesthesiology practices. Our team of experts is dedicated to streamlining your revenue cycle management and maximizing your practice's financial health.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'>At Care-MD, we believe that administrative work should not be the focus of your day. That's why we offer services that help remove the burden of obtaining authorizations, completing medical coding, fixing claims issues, and resolving denials. By taking care of these tasks, we aim to save your staff valuable time and allow them to focus on providing quality care to your patients.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'>Our highly trained coders and billers are well-versed in the complexities of anesthesiology billing. They address billing issues before your claims go out the door, ensuring that you get paid what you're owed. We also work diligently to correct and resolve denials, helping you recover payments quickly and efficiently.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'>By outsourcing your medical billing services to Care-MD, you can experience a range of benefits. Our customized solutions are designed to optimize your revenue cycle management and increase your practice's profitability. By accelerating your cash flow and achieving greater economies of scale, you can improve your financial performance and focus on providing exceptional care to your patients.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'>We understand the importance of HIPAA compliance and high data security in the healthcare industry. That's why Care-MD places a high emphasis on safeguarding patient data and ensuring full compliance with HIPAA regulations. Our staff undergoes rigorous HIPAA training to ensure that they understand the regulations and are in compliance with them. We also use advanced software and technology to protect patient data and prevent unauthorized access.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'>At Care-MD, we strive to make the transition to our services seamless and efficient. Our fully integrated services can be easily incorporated into your daily workflow, without adding vendors or increasing your staff's paperwork. We work closely with you to understand your unique needs and tailor our services accordingly.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'>Partnering with Care-MD means you can have peace of mind knowing that your medical billing is being handled with the highest level of security, efficiency, and expertise. We are dedicated to helping you focus on patient care while we handle the administrative tasks that come with medical billing.</p>
                    </div>
                    <div className='image-container w-[100%] md:w-[40%]'>
                        <div className="image-content" style={{ backgroundImage: `url(${MedicalBilling5})` }} />
                    </div>
                </div>

                <div className='flex flex-col-reverse gap-[40px] md:flex-row mt-[100px]'>
                    <div className='image-container w-[100%] md:w-[40%]'>
                        <div className="image-content" style={{ backgroundImage: `url(${MedicalBilling3})` }} />
                    </div>
                    <div className='w-[100%] md:w-[60%]'>
                        <h1 style={{ color: '#424656' }} className='text-[18px] md:text-[24px] tracking-[0.01em] font-semibold'>Why Choose Care-MD as Your Third-Party Medical Billing Partner?</h1>
                        <p className='text-[14px] md:text-[16px] mt-[10px]'><span className='font-medium text-[15px] md:text-[17px]'>Expertise and Experience :</span> Our team of experts specializes in anesthesiology medical billing. We stay up-to-date with the latest industry changes and can handle any billing-related issues with ease.</p>
                        <p className='text-[14px] md:text-[16px] mt-[10px]'><span className='font-medium text-[15px] md:text-[17px]'>Time-Saving Solutions :</span> Care-MD relieves your staff of time-consuming administrative tasks such as researching requirements, tracking changes, and calling payers for authorizations. Our fully integrated services seamlessly fit into your daily workflow without adding vendors or increasing paperwork.</p>
                        <p className='text-[14px] md:text-[16px] mt-[10px]'><span className='font-medium text-[15px] md:text-[17px]'>Revenue Maximization :</span> With highly trained coders and billers, we address billing issues before claims are submitted, ensuring that you receive the full payment you're owed. We also work diligently to correct and resolve denials, helping you recover payments and increase your revenue.</p>
                        <p className='text-[14px] md:text-[16px] mt-[10px]'><span className='font-medium text-[15px] md:text-[17px]'>Reduced Administrative Workload :</span> Our services remove the burden of obtaining authorizations, completing medical coding, fixing claims issues, and resolving denials. This saves your staff valuable time, allowing them to focus on providing quality care to your patients.</p>
                        <p className='text-[14px] md:text-[16px] mt-[10px]'><span className='font-medium text-[15px] md:text-[17px]'>HIPAA Compliance and Data Security :</span> Care-MD prioritizes the confidentiality and security of patient data. We adhere to strict protocols, undergo rigorous HIPAA training, and employ advanced software and technology to protect patient information.</p>
                    </div>
                </div>

                <h1 style={{ color: '#424656' }} className='text-[18px] md:text-[24px] tracking-[0.01em] font-semibold mt-[50px] md:mt-[70px]'>Partner with Care-MD for Streamlined Medical Billing Services</h1>
                <p className='text-[14px] md:text-[16px] mt-[10px]'>At Care-MD, we understand the value of your time and the importance of streamlining administrative tasks to maximize productivity. Our services are designed to save you time, reduce administrative burdens, and ensure that your patient data is protected.</p>
                <p className='text-[14px] md:text-[16px] mt-[10px]'>Contact us today to learn more about our services and how we can help your practice thrive. Let Care-MD be your trusted partner in optimizing your anesthesiology practice's revenue cycle management. Schedule a free consultation and experience the benefits of our comprehensive and customized medical billing solutions.</p>
            </div>
            <Contact />
            <Footer />
        </div>
    )
}

export default MedicalBillingAndCoadingServices