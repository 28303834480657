import React from 'react'
import { motion } from 'framer-motion'
import Hipaa from '../assets/hipaa.png'
import ISO from '../assets/iso.png'

const CommonHeroSection = ({ image, title }) => {
    return (
        <div className='backgroundImages backgroundImagesDark flex' style={{ backgroundImage: `url(${image})` }}>
            <div className='absolute right-[30px] top-[30px] flex z-10'>
                <motion.img
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.5 }}
                    style={{ backgroundColor: '#fff' }}
                    className='w-[60px] md:w-[70px] h-auto rounded-[50%] p-[5px]'
                    src={Hipaa}
                    alt=""
                />
                <motion.img
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                    style={{ backgroundColor: '#fff' }}
                    className='w-[60px] md:w-[70px] h-auto ml-[20px] rounded-[50%] p-[5px]'
                    src={ISO}
                    alt=""
                />
            </div>

            <div className='flex justify-center items-center'>
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                    className='z-10 left-[30px] absolute p-[20px] md:p-[20px] text-white md:max-w-[650px] rounded-[5px]'
                    style={{ backgroundColor: '#7e9dd4d2' }}>
                    <h1 className='text-[20px] font-semibold sm:text-[28px]'>{title}</h1>
                </motion.div>
            </div>
        </div>
    )
}

export default CommonHeroSection