import React from 'react'
import NavigationBar from '../../components/NavigationBar'
import CommonHeroSection from '../../components/CommonHeroSection'
import MedicalScribeImage from '../../assets/medicalScribe.jpg'
import MedicalScribeImage1 from '../../assets/medicalscribe1.jpg'
import Contact from '../../components/homeSectionComponents/contact/Contact'
import Footer from '../../components/Footer'

const MedicalScribe = () => {
    return (
        <div>
            <NavigationBar />
            <CommonHeroSection image={MedicalScribeImage} title="MEDICAL SCRIBE SERVICES" />
            <div className='m-auto py-[50px] max-w-[1400px] px-[20px] sm:px-[40px]'>
                <div className='flex flex-col gap-[40px] md:flex-row'>
                    <div className='w-[100%] md:w-[55%]'>
                        <h1 style={{ color: '#424656' }} className='text-[20px] md:text-[24px] tracking-[0.01em] font-semibold'>Enhance Efficiency and Streamline Documentation with Our Top-notch Medical Scribe Services</h1>
                        <p className='text-[14px] md:text-[16px] mt-[5px]'>Care-MD is dedicated to providing exceptional medical scribe services that alleviate the burden of data entry and administrative tasks for physicians.</p>
                        <p className='text-[14px] md:text-[16px] mt-[5px]'>Our highly skilled and physician-dedicated medical scribes are equipped to deliver superior support, ensuring a seamless workflow.</p>
                        <p className='text-[14px] md:text-[16px] mt-[5px]'>By utilizing a Virtual Medical Scribe, physicians can experience a significant reduction in burnout, as real-time data is captured by a virtual assistant and directly entered into the Electronic Medical Records (EMR) system during patient visits.</p>
                        <p className='text-[14px] md:text-[16px] mt-[5px]'>This eliminates the need for an assistant to be physically present during examinations, addressing patient concerns and maintaining their comfort.</p>

                        <h1 style={{ color: '#424656' }} className='text-[20px] md:text-[24px] tracking-[0.01em] font-semibold mt-[15px]'>The utilization of a Virtual Scribe offers numerous advantages for healthcare providers:</h1>
                        <p className='text-[14px] md:text-[16px] mt-[5px]'>Medical documentation can be verified, monitored, and immediate corrections can be made if necessary, ensuring accuracy and efficiency in real-time.</p>
                        <p className='text-[14px] md:text-[16px] mt-[5px]'>With our quality virtual/remote scribe solutions, Care-MD empowers practices to create charts in real-time without the need for an assistant's physical presence during examinations.</p>
                        <p className='text-[14px] md:text-[16px] mt-[5px]'>This approach not only eliminates potential discomfort for patients but also enhances the overall professionalism of the medical practice.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'>Partner with Care-MD for Unparalleled Medical Scribe Solutions and Streamlined Documentation.</p>
                    </div>
                    <div className='image-container w-[100%] md:w-[45%]'>
                        <div className="image-content" style={{ backgroundImage: `url(${MedicalScribeImage1})` }} />
                    </div>
                </div>
            </div>
            <Contact />
            <Footer />
        </div>
    )
}

export default MedicalScribe