import React from 'react'
import { motion } from 'framer-motion'

const Experties = () => {
    return (
        <motion.div className='py-[50px] max-w-[1400px] px-[20px] sm:px-[40px] m-auto'
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 100 }
            }}>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[40px] mt-[50px]'>
                    <div className='flex flex-col justify-center items-center p-[15px] rounded-[5px] shadow-lg'>
                        <div className='w-[40px] h-[40px] rounded-full bg-white flex justify-center items-center p-[8px]' style={{ border: '2px solid #AC628E' }}>
                            <svg width="24px" height="24px" viewBox="0 0 24 24" strokeWidth="2" fill="none" xmlns="http://www.w3.org/2000/svg" color="#AC628E">
                                <path d="M8.5 4H6a2 2 0 00-2 2v14a2 2 0 002 2h6M15.5 4H18a2 2 0 012 2v9" stroke="#AC628E" strokeWidth="2" strokeLinecap="round" />
                                <path d="M8 6.4V4.5a.5.5 0 01.5-.5c.276 0 .504-.224.552-.496C9.2 2.652 9.774 1 12 1s2.8 1.652 2.948 2.504c.048.272.276.496.552.496a.5.5 0 01.5.5v1.9a.6.6 0 01-.6.6H8.6a.6.6 0 01-.6-.6z" stroke="#AC628E" strokeWidth="2" strokeLinecap="round" />
                                <path d="M15.5 20.5l2 2 5-5" stroke="#AC628E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        <h4 className='text-[18px] font-semibold my-[10px]'>HIPAA compliance</h4>
                        <p className='text-center text-[14px] md:text-[16px]'>Care-MD emphasizes HIPAA compliance and high data security in our medical billing services. We understand the sensitive nature of patient data and the importance of protecting it. Therefore, we have implemented strict protocols to ensure that all patient information is kept confidential and secure.</p>
                    </div>

                    <div className='flex flex-col justify-center items-center p-[15px] rounded-[5px] shadow-lg'>
                        <div className='w-[40px] h-[40px] rounded-full bg-white flex justify-center items-center p-[8px]' style={{ border: '2px solid #00D9B4' }}>
                            <svg width="24px" height="24px" strokeWidth="2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#00D9B4">
                                <path d="M1 20v-1a7 7 0 017-7v0a7 7 0 017 7v1" stroke="#00D9B4" strokeWidth="2" strokeLinecap="round" />
                                <path d="M13 14v0a5 5 0 015-5v0a5 5 0 015 5v.5" stroke="#00D9B4" strokeWidth="2" strokeLinecap="round" />
                                <path d="M8 12a4 4 0 100-8 4 4 0 000 8zM18 9a3 3 0 100-6 3 3 0 000 6z" stroke="#00D9B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        <h4 className='text-[18px] font-semibold my-[10px]'>Well Trained Professionals</h4>
                        <p className='text-center text-[14px] md:text-[16px]'>Our staff undergoes rigorous HIPAA training to ensure that they understand the regulations and are in compliance with them. We also use advanced software and technology to safeguard patient data and prevent unauthorized access.</p>
                    </div>

                    <div className='flex flex-col justify-center items-center p-[15px] rounded-[5px] shadow-lg'>
                        <div className='w-[40px] h-[40px] rounded-full bg-white flex justify-center items-center p-[8px]' style={{ border: '2px solid #B75A00' }}>
                            <svg width="24px" height="24px" strokeWidth="2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#B75A00">
                                <path d="M8 16V8M12 16v-5M16 16v-3" stroke="#B75A00" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M3 20.4V3.6a.6.6 0 01.6-.6h16.8a.6.6 0 01.6.6v16.8a.6.6 0 01-.6.6H3.6a.6.6 0 01-.6-.6z" stroke="#B75A00" strokeWidth="2" />
                            </svg>
                        </div>
                        <h4 className='text-[18px] font-semibold my-[10px]'>Audit and Reviews by Experts</h4>
                        <p className='text-center text-[14px] md:text-[16px]'>In addition, we regularly conduct audits and reviews to identify any potential security risks and take proactive measures to address them. Our goal is to provide our clients with the peace of mind that their patient data is protected and secure.</p>
                    </div>
                </div>
        </motion.div>
    )
}

export default Experties