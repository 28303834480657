import React from 'react'
import NavigationBar from '../../components/NavigationBar'
import Accounts from '../../assets/accounts.jpg'
import Accounts1 from '../../assets/accounts1.jpg'
import Accounts2 from '../../assets/accounts2.jpg'
import CommonHeroSection from '../../components/CommonHeroSection'
import Contact from '../../components/homeSectionComponents/contact/Contact'
import Footer from '../../components/Footer'

const AccountsReceivableServices = () => {
    return (
        <div>
            <NavigationBar />
            <CommonHeroSection image={Accounts} title="ACCOUNTS RECEIVABLE SERVICES" />
            <div className='m-auto py-[50px] max-w-[1400px] px-[20px] sm:px-[40px]'>
                <div className='flex flex-col gap-[40px] md:flex-row'>
                    <div className='w-[100%] md:w-[55%]'>
                        <h1 style={{ color: '#424656' }} className='text-[20px] md:text-[24px] tracking-[0.01em] font-semibold'>Care-MD: Your Trusted Partner for Efficient Accounts Receivable Management Services</h1>
                        <p className='text-[14px] md:text-[16px] mt-[5px]'>At Care-MD, we understand that the primary focus of every healthcare facility and physician's office is providing quality patient care. However, managing accounts receivable can often become a complex and time-consuming task, leading to revenue crunches and compromised cash flow. That's where our expert accounts receivable management services come in.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'>Outsourcing your accounts receivable management to Care-MD allows you to streamline your revenue cycle, improve cash flow, and ensure timely remuneration. Our dedicated team of professionals specializes in a range of processes to optimize your accounts receivable operations.</p>
                    </div>
                    <div className='image-container w-[100%] md:w-[45%]'>
                        <div className="image-content" style={{ backgroundImage: `url(${Accounts1})` }} />
                    </div>
                </div>

                <div className='flex flex-col-reverse gap-[40px] md:flex-row mt-[100px]'>
                <div className='image-container w-[100%] md:w-[40%]'>
                        <div className="image-content" style={{ backgroundImage: `url(${Accounts2})` }} />
                    </div>
                    <div className='w-[100%] md:w-[60%]'>
                        <h4 style={{ color: '#424656' }} className='text-[20px] md:text-[24px] tracking-[0.01em] font-semibold'>Key Processes in Accounts Receivable Management Services</h4>
                        <p className='text-[14px] md:text-[16px] mt-[10px]'><span className='font-medium text-[15px] md:text-[17px]'>Timely Dispatch and Follow-up :</span> We ensure that the correct invoices are generated promptly and sent to insurance companies or patients. Timely follow-up is crucial to reduce wait times and expedite payment processing.</p>
                        <p className='text-[14px] md:text-[16px] mt-[10px]'><span className='font-medium text-[15px] md:text-[17px]'>Denial Management :</span> Our experts focus on generating accurate invoices to minimize denials. However, if a denial occurs, we swiftly identify the cause and take corrective measures, ensuring a healthy revenue cycle.</p>
                        <p className='text-[14px] md:text-[16px] mt-[10px]'><span className='font-medium text-[15px] md:text-[17px]'>Refiling Claim Submissions :</span> In cases where corrections are required, we promptly refile the claims within the advised time period to ensure you receive the rightful reimbursement.</p>
                        <p className='text-[14px] md:text-[16px] mt-[10px]'><span className='font-medium text-[15px] md:text-[17px]'>Periodic Reviews :</span> Care-MD stays up to date with the ever-changing regulations in the healthcare industry. We conduct regular reviews of our processes to ensure maximum efficiency and compliance with industry standards.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'>By partnering with Care-MD for your accounts receivable management needs, you can free up valuable resources and focus on patient care while we handle the complexities of revenue cycle management. Our team of experts brings in-depth knowledge and extensive experience to optimize your accounts receivable processes and improve your financial performance.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'>Trust Care-MD to deliver exceptional accounts receivable management services, enabling you to maintain a healthy revenue cycle, enhance cash flow, and ensure timely payments. Experience the difference that our expertise and commitment can make for your healthcare facility or physician's office.</p>
                    </div>
                </div>
            </div>
            <Contact />
            <Footer />
        </div>
    )
}

export default AccountsReceivableServices