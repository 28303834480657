import React from 'react'
import Management from '../../../assets/management.jpg'
import Legal from '../../../assets/legal.jpg'
import Records from '../../../assets/records.jpg'
import MedicalScribe from '../../../assets/medicalScribe.jpg'
import Teleradiology from '../../../assets/Teleradiology.jpg'
import Accounts from '../../../assets/accounts.jpg'
import CallCenter from '../../../assets/callCenter.jpg'
import MedicalCoading from '../../../assets/medicalCoading.jpg'
import { motion } from 'framer-motion'
import { NavLink } from 'react-router-dom'

const Animation = ({ children }) => {
    return <motion.div className='flex h-full rounded-[5px]' style={{ backgroundColor: '#E7EFFF' }}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.5 }}
        variants={{
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: 100 }
        }}>
        {children}
    </motion.div>
}

const Services = () => {
    return (
        <div className='py-[50px] max-w-[1400px] px-[20px] sm:px-[40px] m-auto'>
            <h1 style={{ color: '#424656' }} className='text-center text-[32px] tracking-[0.01em] font-semibold'>Our Services</h1>

            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-[20px] mt-[30px]'>
                <Animation>
                    <NavLink to="/appointment-scheduling-and-call-center">
                        <div className='p-[20px]'>
                            <img className='h-auto w-[100%] object-fill rounded-[3px]' src={CallCenter} alt='' />
                            <h4 className='my-[15px] text-[16px] md:text-[18px] text-center font-medium'>APPOINTMENT SCHEDULING & CALL CENTER</h4>
                            <p className='text-[14px] text-center'>Efficient appointment scheduling is crucial for your business, avoiding costly distractions and missed opportunities. Partnering with a professional appointment scheduling call center, like Care-MD, ensures professional handling of appointment-related calls, allowing you to focus on your business operations.</p>
                        </div>
                    </NavLink>
                </Animation>

                <Animation>
                    <NavLink to="/medical-billing-and-coding">
                        <div className='p-[20px]'>
                            <img className='h-auto w-[100%] object-fill rounded-[3px]' src={MedicalCoading} alt='' />
                            <h4 className='my-[15px] text-[16px] md:text-[18px] text-center font-medium'>MEDICAL BILLING & CODING</h4>
                            <p className='text-[14px] text-center'>Medical Coding & Billing is the backbone of healthcare revenue management, ensuring accurate coding, timely claim submission, and optimal reimbursement for medical services rendered. Trust us to handle your coding and billing needs with expertise and precision.</p>
                        </div>
                    </NavLink>
                </Animation>

                <Animation>
                    <NavLink to="/accounts-receivable-services">
                        <div className='p-[20px]'>
                            <img className='h-auto w-[100%] object-fill rounded-[3px]' src={Accounts} alt='' />
                            <h4 className='my-[15px] text-[16px] md:text-[18px] text-center font-medium'>ACCOUNTS RECEIVABLE SERVICES</h4>
                            <p className='text-[14px] text-center'>Accounts Receivable Services involve effectively managing customer invoices and collections to enhance cash flow and minimize outstanding payments, ensuring financial optimization for your company. Let us lighten your workload and ensure AR management.</p>
                        </div>
                    </NavLink>
                </Animation>

                <Animation>
                    <NavLink to="/revenue-cycle-management">
                        <div className='p-[20px]'>
                            <img className='h-auto w-[100%] object-fill rounded-[3px]' src={Management} alt='' />
                            <h4 className='my-[15px] text-[16px] md:text-[18px] text-center font-medium'>REVENUE CYCLE MANAGEMENT</h4>
                            <p className='text-[14px] text-center'>Revenue Cycle Management (RCM) is a strategic approach that aims to streamline and optimize the financial processes in healthcare, ensuring prompt and accurate reimbursement for the services rendered. Trust us to enhance your revenue cycle and drive financial success for your practice.</p>
                        </div>
                    </NavLink>
                </Animation>

                <Animation>
                    <NavLink to="/medical-scribe-services">
                        <div className='p-[20px]'>
                            <img className='h-auto w-[100%] object-fill rounded-[3px]' src={MedicalScribe} alt='' />
                            <h4 className='my-[15px] text-[16px] md:text-[18px] text-center font-medium'>MEDICAL SCRIBE</h4>
                            <p className='text-[14px] text-center'>A medical scribe is a valuable asset to healthcare providers, ensuring accurate and thorough documentation of patient encounters. By alleviating administrative burden, our medical scribe services enhance workflow efficiency, allowing providers to focus on delivering quality care.</p>
                        </div>
                    </NavLink>
                </Animation>

                <Animation>
                    <NavLink to="/medical-records-review">
                        <div className='p-[20px]'>
                            <img className='h-auto w-[100%] object-fill rounded-[3px]' src={Records} alt='' />
                            <h4 className='my-[15px] text-[16px] md:text-[18px] text-center font-medium'>MEDICAL RECORDS REVIEW</h4>
                            <p className='text-[14px] text-center'>Medical records review is a critical process in healthcare, enabling comprehensive evaluation of a patient's health history, treatments, and diagnostic information. Our expert team conducts thorough medical records reviews, providing valuable insights for medical decision-making.</p>
                        </div>
                    </NavLink>
                </Animation>

                <Animation>
                    <NavLink to="/medico-legal">
                        <div className='p-[20px]'>
                            <img className='h-auto w-[100%] object-fill rounded-[3px]' src={Legal} alt='' />
                            <h4 className='my-[15px] text-[16px] md:text-[18px] text-center font-medium'>MEDICO - LEGAL</h4>
                            <p className='text-[14px] text-center'>Medico-legal involves the convergence of medicine and law, addressing health-related issues, injuries, and their impact on legal proceedings. Our comprehensive medico-legal services provide expert insights and support to navigate complex healthcare legal matters.</p>
                        </div>
                    </NavLink>
                </Animation>

                <Animation>
                    <NavLink to="/teleradiology">
                        <div className='p-[20px]'>
                            <img className='h-auto w-[100%] object-fill rounded-[3px]' src={Teleradiology} alt='' />
                            <h4 className='my-[15px] text-[16px] md:text-[18px] text-center font-medium'>TELERADIOLOGY</h4>
                            <p className='text-[14px] text-center'>Teleradiology is a telemedicine solution that facilitates the remote interpretation and analysis of medical images by our experienced radiologists. By leveraging advanced technology, we enable timely and accurate diagnoses, ensuring patients receive quality care regardless of their location.</p>
                        </div>
                    </NavLink>
                </Animation>
            </div>
        </div>
    )
}

export default Services