import React from 'react'
import NavigationBar from '../../components/NavigationBar'
import CommonHeroSection from '../../components/CommonHeroSection'
import PricingImage from '../../assets/pricing.jpg'
import Contact from '../../components/homeSectionComponents/contact/Contact'
import Footer from '../../components/Footer'
import Pricing1 from '../../assets/pricing1.jpg'

const Pricing = () => {
    return (
        <div>
            <NavigationBar />
            <CommonHeroSection image={PricingImage} title="PRICING" />
            <div className='m-auto py-[50px] max-w-[1400px] px-[20px] sm:px-[40px]'>
                <h1 style={{ color: '#424656' }} className='text-[20px] md:text-[24px] tracking-[0.01em] font-semibold'>Care-MD: Your Trusted Partner for Affordable and Effective Business Service Solutions</h1>
                <p className='text-[14px] md:text-[16px] mt-[5px]'>At Care-MD, we understand the importance of cost-effective business service solutions for healthcare providers. Our comprehensive range of services is designed to optimize your operations while delivering exceptional value for your investment. When you choose Care-MD, you gain access to cutting-edge technology, expert support, and customized solutions that drive efficiency and maximize your return on investment.</p>

                <div className='flex flex-col gap-[40px] md:flex-row mt-[30px] md:mt-[50px]'>
                    <div className='w-[100%] md:w-[60%]'>
                        <h1 style={{ color: '#424656' }} className='text-[20px] md:text-[24px] tracking-[0.01em] font-semibold'>Why Choose Care-MD for Your Business Service Solutions</h1>
                        <p className='text-[14px] md:text-[16px] mt-[5px]'><span className='font-medium text-[15px] md:text-[17px]'>Competitive Pricing :</span> Care-MD offers highly competitive pricing for our business service solutions. We understand the financial challenges faced by healthcare providers, and our aim is to provide cost-effective options that deliver tangible results. Our pricing structure is transparent, ensuring you receive outstanding value without compromising on quality.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'><span className='font-medium text-[15px] md:text-[17px]'>Tailored Solutions :</span> We recognize that every healthcare organization is unique, with specific needs and objectives. That's why Care-MD offers tailored solutions that align with your business requirements. Our team works closely with you to understand your goals and challenges, developing customized strategies that address your specific pain points and optimize your processes.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'><span className='font-medium text-[15px] md:text-[17px]'>Advanced Technology :</span> Care-MD leverages cutting-edge technology to streamline your business operations. Our solutions are built on robust platforms that integrate seamlessly with your existing systems, eliminating inefficiencies and enhancing productivity. By harnessing the power of automation and intelligent tools, we help you achieve operational excellence and drive growth.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'><span className='font-medium text-[15px] md:text-[17px]'>Expert Support :</span> With Care-MD, you benefit from the expertise of our highly skilled professionals. Our team has in-depth knowledge of the healthcare industry and extensive experience in providing business service solutions. We provide comprehensive support throughout the implementation and ongoing management of our services, ensuring smooth integration and optimal performance.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'><span className='font-medium text-[15px] md:text-[17px]'>Cost Savings and ROI :</span> By partnering with Care-MD, you can achieve significant cost savings and a strong return on investment. Our solutions are designed to streamline workflows, reduce administrative burdens, and minimize overhead expenses. With improved efficiency and productivity, you can focus on delivering exceptional patient care while maximizing your financial performance.</p>
                    </div>
                    <div className='image-container w-[100%] md:w-[40%]'>
                        <div className="image-content" style={{ backgroundImage: `url(${Pricing1})` }} />
                    </div>
                </div>

                <h1 style={{ color: '#424656' }} className='text-[20px] md:text-[24px] tracking-[0.01em] font-semibold mt-[50px]'>Contact Us for Pricing Details</h1>
                <p className='text-[14px] md:text-[16px] mt-[5px]'>To learn more about our competitive pricing and explore how Care-MD can tailor business service solutions to meet your needs, please contact our dedicated team. We will provide you with a detailed pricing breakdown based on your specific requirements, enabling you to make an informed decision that aligns with your budget and goals.</p>

                <h1 style={{ color: '#424656' }} className='text-[20px] md:text-[24px] tracking-[0.01em] font-semibold mt-[50px]'>Experience Affordable Excellence with Care-MD</h1>
                <p className='text-[14px] md:text-[16px] mt-[5px]'>Experience the power of affordable excellence with Care-MD as your trusted partner for business service solutions. We are committed to delivering exceptional value, advanced technology, and expert support that drives success for your healthcare organization. Let us help you achieve operational efficiency, cost savings, and improved outcomes.</p>
            </div>
            <Contact />
            <Footer />
        </div>
    )
}

export default Pricing