import React from 'react'
import NavigationBar from '../../components/NavigationBar'
import CommonHeroSection from '../../components/CommonHeroSection'
import Billing from '../../assets/billing.jpg'
import AboutImage from '../../assets/about.jpg'
import Contact from '../../components/homeSectionComponents/contact/Contact'
import Footer from '../../components/Footer'

const About = () => {
    return (
        <div>
            <NavigationBar />
            <CommonHeroSection image={Billing} title="ABOUT" />
            <div className='m-auto py-[50px] max-w-[1400px] px-[20px] sm:px-[40px]'>
                <div className='flex flex-col gap-[40px] md:flex-row'>
                    <div className='w-[100%] md:w-[60%]'>
                        <h1 style={{ color: '#424656' }} className='text-[20px] md:text-[28px] tracking-[0.01em] font-semibold'>Welcome to Care-MD, where your business support solutions matter to us!</h1>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'>Are you ready to optimize your business support solutions in the USA? Look no further than Care-MD, a trustworthy and empathic Texas-based company with over 20 years of experience in providing exceptional support. Our team of highly trained and knowledgeable professionals specializes in a wide range of complex tasks, including inbound/outbound calls, scheduling/rescheduling, medico-legal services (workers’ compensation cases), medical coding, medical billing, medical transcription, medical records review, teleradiology and much more.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'>By entrusting your services to Care-MD, a multitude of benefits await. We'll not only help you accelerate your cash flow and achieve greater economies of scale but also liberate your valuable time, enabling you to concentrate on providing exceptional customer care while maintaining the highest level of professionalism. Our dedicated team ensures that you experience the best of both worlds. Our devoted professionals are passionately committed to maximizing your revenue, guaranteeing precise and superior outcomes. They accomplish this by skillfully applying appropriate modifiers to a wide range of situational procedures and reporting them with utmost accuracy in precise time units.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'>Partnering with Care-MD means reducing your account receivables, lowering overheads, and operating costs for your organization. Our team stays up-to-date with the latest industry regulations, empowering your organization to maintain a higher level of efficiency in providing top-notch medical/legal care and delivering exceptional customer service.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'>When you choose Care-MD, you're choosing a trusted partner dedicated to helping you maximize your return on investment (ROI) and enhancing your clinical efficiency. We prioritize strict HIPAA compliance and maintain the highest standards of data security, ensuring your sensitive information is always protected. With Care-MD, you can trust that your financial success is in capable hands.</p>
                        <p className='text-[14px] md:text-[16px] mt-[15px]'>Don't miss out on the opportunity to revolutionize your business support solutions. Schedule a free consultation with our team of experts today and discover how Care-MD can infuse professionalism, trustworthiness, empathy, and even a touch of fun into your organization. Let us join forces and drive success and vitality in the dynamic landscape of the USA. Because at Care-MD, we truly care about your success!</p>
                    </div>
                    <div className='image-container w-[100%] md:w-[40%]'>
                        <div className="image-content" style={{ backgroundImage: `url(${AboutImage})` }} />
                    </div>
                </div>
            </div>
            <Contact />
            <Footer />
        </div>
    )
}

export default About